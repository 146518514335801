import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import Select from "react-select";
const CreateModal = ({
  show,
  toggleModal,
  submit,
  title,
  user,
  handleCreate,
  rol,
  error,
}) => {
  return (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
      })}
    >
      <React.Fragment>
        <Modal show={show} onHide={() => toggleModal("create")} centered>
          <Modal.Header closeButton>{title}</Modal.Header>
          <Modal.Body className="text-center m-3">
            <Container>
              <Row>
                <Col sm={6} className="pb-3">
                  <InputGroup>
                    <InputGroup.Text>Nombre</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="user"
                      placeholder="Ingrese el nombre"
                      value={user.name}
                      onChange={handleCreate}
                    />
                  </InputGroup>
                </Col>
                <Col sm={6}>
                  <InputGroup>
                    <InputGroup.Text>Correo</InputGroup.Text>
                    <Form.Control
                      type="email"
                      name="email"
                      value={user.email}
                      placeholder="Ingrese el correo"
                      onChange={handleCreate}
                    />
                  </InputGroup>
                </Col>
                <Col sm={6}>
                  <InputGroup>
                    <Select
                      className="react-select-container w-100"
                      classNamePrefix="select"
                      placeholder="Seleccionar Rol"
                      options={rol}
                      onChange={handleCreate}
                    />
                  </InputGroup>
                </Col>
                <Col sm={6}>
                  <InputGroup>
                    <InputGroup.Text>Contraseña</InputGroup.Text>
                    <Form.Control
                      type="password"
                      name="password"
                      value={user.password}
                      placeholder="Ingrese la contraseña"
                      onChange={handleCreate}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Container>
            {error.isError && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{error.message}</div>
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => toggleModal("create")}>
              Cerrar
            </Button>
            <Button onClick={submit}>Guardar</Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    </Formik>
  );
};

export default CreateModal;
