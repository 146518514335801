import axios from "../utils/axios";
export const fetchUserUpdateLogo = async ({ logo }) => {
  const { data } = await axios.post("api/company/setLogo", logo, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};
export const fetchUserUpdate = async ({ userId, request }) => {
  const { data } = await axios.patch(
    `api/user/${userId}/edit-profile`,
    request
  );
  return data;
};
