import { useQuery } from "react-query";
import { Card, Spinner } from "react-bootstrap";
import MaleFemale from "../../pages/training/reportes/charts/MaleFemale";
import { fetchInscritosAlmenosUnCurso } from "../../services/capacitaciones";
import NotDataCard from "../../pages/pages/NotDataCard";
const SelectorExample = () => {
  const {
    data: stats,
    error,
    isLoading,
  } = useQuery(["inscritosAlmenosUnCurso"], fetchInscritosAlmenosUnCurso);
  if (isLoading) {
    return <Spinner animation="border" />;
  }
  if (error) {
    return <p>Hubo un error inesperado</p>;
  }

  return (
    <div>
      <Card style={{ width: "100%", marginTop: "2em" }}>
        <Card.Header>
          <h6 className="card-subtitle text-muted">
            Trabajadores inscritos en al menos un curso.
          </h6>
        </Card.Header>
        <Card.Body>
          {stats.length > 0 ? <MaleFemale /> : <NotDataCard />}
        </Card.Body>
      </Card>
    </div>
  );
};

export default SelectorExample;
