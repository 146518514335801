import { Col, Row, Table } from "react-bootstrap";

const DefaultTable = ({ columns, children }) => {
  return (
    <Row>
      <Col>
        <Table striped bordered hover>
          <thead>
            <tr>
              {columns.map((col) => (
                <th key={col}>{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </Table>
      </Col>
    </Row>
  );
};
export default DefaultTable;
