import React from "react";
import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";
import usePalette from "../../../hooks/usePalette";
import { formatter } from "../../../utils/utils";
import NotDataCard from "../../pages/NotDataCard";
const InversionPorDimension = ({ stats }) => {
  const palette = usePalette();
  const data = [
    {
      name: "Dimension",
      data: stats.map((div) => div.sum),
    },
  ];
  const options = {
    chart: {
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    dataLabels: {
      formatter: function (value) {
        return formatter.format(value);
      },
      offsetX: 15,
      offsetY: 0,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      labels: {
        formatter: function (value) {
          return formatter.format(value);
        },
      },
      categories: [...new Set(stats.map((div) => div.name))],
    },
    yaxis: {
      labels: {
        maxWidth: 300,
      },

      title: {
        text: undefined,
      },
    },

    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
    colors: [
      palette.primary,
      palette.success,
      palette.warning,
      palette.danger,
      palette.info,
    ],
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">Dimensiones</Card.Title>
        <h6 className="card-subtitle text-muted">
          Inversión en capacitación por dimensión
        </h6>
      </Card.Header>
      <Card.Body>
        {stats.length > 0 ? (
          <div className="chart">
            <Chart options={options} series={data} type="bar" height="500" />
          </div>
        ) : (
          <NotDataCard />
        )}
      </Card.Body>
    </Card>
  );
};

export default InversionPorDimension;
