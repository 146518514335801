import { Col, Row, Spinner } from "react-bootstrap";
import useReportes from "../hooks/useReportes";
import { TabDivision } from "./tabs";

const ReportesPresupuesto = () => {
  const { data, isLoading } = useReportes();
  if (isLoading) {
    return <Spinner animation="border" />;
  }
  return (
    <>
      <h2>Resumen inversión presupuesto Capacitación</h2>
      <Row>
        <Col sm="12">
          <TabDivision stats={data} />
          {/*<InversionPorDimension stats={data} />*/}
        </Col>
        {/*<Col xl="6">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Divisiones</th>
                <th width="15%">Inversión por división</th>
              </tr>
            </thead>
            <tbody>
              {data.map((div) => (
                <tr key={div.division_id}>
                  <td>{div.name}</td>
                  <td
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span>$</span>
                    <span>{div.money}</span>
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <strong>Inversión total Capacitación 2021</strong>
                </td>
                <td
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <strong>$</strong>
                  <strong>{calculateTotal()}</strong>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col xl="6">
          <InversionPorDimensionPie stats={data} />
          <InversionPorDimensionDonut stats={data} />
        </Col>
        <Col>
          <InversionPorDimensionTwo stats={data} />
        </Col>*/}
      </Row>
    </>
  );
};
export default ReportesPresupuesto;
