import { useState } from "react";
import { Fragment } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import CreateModal from "../../../components/company/modal/create";
import EditModal from "../../../components/company/modal/edit";
import PaginationTable from "../../../components/table/CrudTable";
import ActionModal from "../../../components/user/modal/actions";
import DeleteModal from "../../../components/user/modal/delete";
import { ComapnyAll } from "../../../hooks/useCompany";
import { format } from "../../../utils/validatorRut";
import { tableColumnsCompany } from "./dataTable/data";

const CompanyTable = () => {
  const [modal, setModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [company, setCompany] = useState({ id: 0, name: "", rut: "" });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState({ isError: false, message: "" });
  const [message, setMessage] = useState("");
  const [companyCreate, setCompanyCreate] = useState({
    name: "",
    rut: "",
  });
  const { currentPage, lastPage, companies, edit, add, deleted } = ComapnyAll();
  const actionHandler = (action, company) => {
    if (company !== undefined) {
      setCompany({
        id: company[0].value,
        name: company[1].value,
        rut: company[2].value,
      });
    }
    switch (action) {
      case "create":
        setCreateModal(!createModal);
        break;
      case "editar":
        setEditModal(!editModal);
        break;
      case "action":
        setActionModal(!actionModal);
        break;
      case "delete":
        setDeleteModal(!deleteModal);
        break;
      default:
        break;
    }
    setModal(!modal);
  };
  const handleEdit = (e) => {
    if (e.target.name === "company") {
      setCompany({ ...company, name: e.target.value });
    }
    if (e.target.name === "rut") {
      let rut = format(e.target.value, { dots: false });
      setCompany({ ...company, rut: rut });
    }
  };
  const handleSubmitEdit = async () => {
    const editar = await edit(company);
    setMessage(editar.message);
    setSuccess(editar.success);
    actionHandler("editar");
    actionHandler("action");
  };
  const handleCreate = (e) => {
    if (e.target.name === "company") {
      setCompanyCreate({ ...companyCreate, name: e.target.value });
    }
    if (e.target.name === "rut") {
      let rut = format(e.target.value, { dots: false });
      setCompanyCreate({ ...companyCreate, rut: rut });
    }
  };
  const handleSubmitCreate = async () => {
    try {
      const create = await add(companyCreate);
      setMessage(create.message);
      setSuccess(create.success);
      actionHandler("create");
      actionHandler("action");
    } catch (errors) {
      setError({
        ...error,
        isError: true,
        message: errors.errors ? errors.errors : "La empresa ya existe",
      });
    }
  };
  const modalCreate = () => {
    actionHandler("create");
  };
  const handleSubmitDelete = async () => {
    const create = await deleted(company);
    setMessage(create.message);
    setSuccess(create.success);
    actionHandler("delete");
    actionHandler("action");
  };
  return (
    <Fragment>
      <Container>
        <Row className="pb-3">
          <Col></Col>
          <Col md="auto"></Col>
          <Col xs lg="2">
            <Button onClick={modalCreate}>Agregar</Button>
          </Col>
        </Row>
      </Container>
      <PaginationTable
        title={"Listado de Empresas"}
        currentPage={currentPage}
        lastPage={lastPage}
        columns={tableColumnsCompany}
        data={companies}
        toggleModal={actionHandler}
        useHook={ComapnyAll}
      />
      <EditModal
        show={editModal}
        toggleModal={actionHandler}
        submit={handleSubmitEdit}
        company={company}
        title="Editar Empresa"
        handleEdit={handleEdit}
      />
      <CreateModal
        show={createModal}
        toggleModal={actionHandler}
        submit={handleSubmitCreate}
        company={companyCreate}
        title="Crear Empresa"
        handleCreate={handleCreate}
        error={error}
      />
      <DeleteModal
        show={deleteModal}
        toggleModal={actionHandler}
        submit={handleSubmitDelete}
        type="la empresa"
      />
      <ActionModal
        show={actionModal}
        toggleModal={actionHandler}
        message={message}
        success={success}
      />
    </Fragment>
  );
};

export default CompanyTable;
