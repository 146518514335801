import React from "react";
import { Card, Col, Form, Pagination, Row, Table } from "react-bootstrap";
import { Edit2, Trash } from "react-feather";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { GlobalFilter, fuzzyTextFilterFn } from "../../utils/GlobalFilter";

const PaginationTable = ({
  title,
  currentPage,
  lastPage,
  columns,
  data,
  toggleModal,
  useHook,
}) => {
  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: { pageIndex: 0, globalFilter: "" },
    },
    useGlobalFilter,
    usePagination
  );
  const pageNow = currentPage ?? pageIndex + 1;
  const totalPages = lastPage ?? pageSize;

  const { refresh } = useHook();

  const toPage = !lastPage
    ? gotoPage
    : (page) => {
        try {
          refresh(page);
        } catch (error) {
          console.error(error);
        }
      };

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title tag="h5">
            {title}
            <div className="card-actions float-end">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                useHook={useHook}
              />
            </div>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Table responsive striped bordered {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, i) => {
                return (
                  <tr key={i}>
                    {headerGroup.headers.map((column, i) => {
                      return (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      );
                    })}
                    {toggleModal && <th>Acciones</th>}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                    {toggleModal && (
                      <td className="table-action">
                        <Edit2
                          className="align-middle me-1"
                          size={18}
                          onClick={() => toggleModal("editar", row.cells)}
                        />
                        <Trash
                          className="align-middle"
                          size={18}
                          onClick={() => toggleModal("delete", row.cells)}
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <Row>
            <Col md="6">
              <span className="mx-2">
                Página{" "}
                <strong>
                  {pageNow} de {totalPages}
                </strong>
              </span>
              <span className="ms-3 me-2">Ir a página:</span>
              <Form.Control
                className="d-inline-block"
                min={1}
                type="number"
                defaultValue={pageNow}
                onChange={(e) => {
                  const page =
                    e.target.value > 0 && e.target.value <= totalPages
                      ? Number(e.target.value)
                      : 1;
                  toPage(page);
                }}
                style={{ width: "75px" }}
              />
            </Col>
            <Col md="6">
              <Pagination className="float-end">
                <Pagination.First
                  onClick={() => toPage(1)}
                  disabled={!canPreviousPage}
                />
                <Pagination.Prev
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />
                <Pagination.Next
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
                <Pagination.Last
                  onClick={() => toPage(totalPages)}
                  disabled={!canNextPage}
                />
              </Pagination>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default PaginationTable;
