import axios from "../utils/axios";
export const fetchAdminImportParticipations = async ({ request }) => {
  const { data } = await axios.post("api/admin/participation/import", request, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};
export const fetchAdminCompany = async () => {
  const { data } = await axios.get("api/company/available-companies", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  console.log(data.data);

  return data.data.map((company_data) => ({
    value: company_data.company_id,
    label: company_data.company.name,
    selected: company_data.selected,
  }));
};

export const fetchAdminDivision = async ({ id_company }) => {
  const { data } = await axios.get(
    "/api/admin/company/" + id_company + "/divisions",
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
};

export const fetchCompanies = async () => {
  const { data } = await axios.get("/api/admin/company");
  return data.data;
};
