import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Modal } from "react-bootstrap";
const ActionModal = ({ show, toggleModal, message, success }) => {
  return (
    <React.Fragment>
      <Modal show={show} onHide={() => toggleModal("action")} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center m-3">
          <FontAwesomeIcon
            icon={success ? faCheck : faExclamationCircle}
            style={{
              width: "100%",
              color: success ? "green" : "red",
              height: "80px",
            }}
          />
          <h1>{message}</h1>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ActionModal;
