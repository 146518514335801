import axios from "../utils/axios";

export const fetchResultReport = async () => {
  try {
    const { data } = await axios.get("api/results-stats");
    return data.data;
  } catch (error) {
    console.log("Error fetchResultReport", error);
    return null;
  }
};
