import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";
import { tableColumns } from "../data.js";
import CreateModal from "../../../components/training/modals/create.js";
import useParticipations from "../../../hooks/useParticipations.js";
import NotData from "../../pages/NotData.js";
import PaginationAccordion from "../../../components/table/CrudAccordion.js";

const PaginationPage = () => {
  const [modal, setModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const { currentPage, lastPage, participations } = useParticipations();

  useEffect(() => {}, []);
  if (participations.length === 0) {
    return <NotData title="No existen datos de participantes" />;
  }
  const actionHandler = (action) => {
    switch (action) {
      case 1:
        setCreateModal(!createModal);
        break;
      case 2:
        setEditModal(!editModal);
        break;
      case 3:
        setDeleteModal(!deleteModal);
        break;
      default:
        break;
    }
    setModal(!modal);
  };

  return (
    <React.Fragment>
      <Helmet title="Pagination" />
      <Container fluid className="p-0">
        <Row>
          <Col className="mt-0">
            <h1 className="h3 mb-3">Capacitaciones para el período actual</h1>
          </Col>

          <Col xs="auto">
            {/* <div className="stat stat-sm">
              <Users className="align-middle text-success" />
            </div> */}
          </Col>
        </Row>
        <PaginationAccordion
          title={"Listado de capacitaciones"}
          currentPage={currentPage}
          lastPage={lastPage}
          columns={tableColumns}
          data={participations}
          toggleModal={actionHandler}
          useHook={useParticipations}
        />
        <CreateModal show={createModal} toggleModal={actionHandler} />
      </Container>
    </React.Fragment>
  );
};

export default PaginationPage;
