import { Col, Tab, Table, Tabs } from "react-bootstrap";
import { formatter, removeSignoPeso } from "../../../utils/utils";
import NotDataCard from "../../pages/NotDataCard";
import InversionPorDimension from "../charts/InversionPorDimension";
import InversionPorDimensionDonut from "../charts/InversionPorDimensionDonut";
import InversionPorDimensionPie from "../charts/InversionPorDimensionPie";
import InversionPorDimensionTwo from "../charts/InversionPorDimensionTwo";

export const TabDivision = ({ stats }) => {
  return (
    <>
      <Tabs defaultActiveKey="0" id="uncontrolled-tab-example" className="mb-3">
        {stats !== undefined &&
          Object.keys(stats.data).map((keys, key) => {
            let total = 0;
            return (
              <Tab eventKey={key} title={keys} key={key}>
                <InversionPorDimension stats={stats.data[keys]} />
                {stats.data[keys].length > 0 ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Divisiones</th>
                        <th width="15%">Inversión por división</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stats.data[keys].map((div, key) => {
                        total += div.sum;
                        return (
                          <tr key={key}>
                            <td>{div.name}</td>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>$</span>
                              <span>
                                {removeSignoPeso(formatter.format(div.sum))}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td>
                          <strong>Inversión total Capacitación 2021</strong>
                        </td>
                        <td
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <strong>$</strong>
                          {
                            <strong>
                              {removeSignoPeso(formatter.format(total))}
                            </strong>
                          }
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  <NotDataCard />
                )}
                <Col xl="6">
                  <InversionPorDimensionPie stats={stats.data[keys]} />
                  <InversionPorDimensionDonut stats={stats.data[keys]} />
                </Col>
                <Col>
                  <InversionPorDimensionTwo stats={stats.data[keys]} />
                </Col>
              </Tab>
            );
          })}
      </Tabs>
    </>
  );
};
