import { Fragment } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import UserTable from "./UserTable";
const UserMaintainer = () => {
  return (
    <Fragment>
      <Helmet title="Mantenedor De Usuarios" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Mantenedor de usuario</h1>
        <Row>
          <Col>
            <Card>
              <Card.Header></Card.Header>
              <Card.Body>
                <UserTable />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UserMaintainer;
