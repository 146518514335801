import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";
import NotDataCard from "../../pages/NotDataCard";

const InversionPorDimensionTwo = ({ stats }) => {
  const series = [{ data: stats.map((div) => div.sum) }];

  const options = {
    chart: {
      type: "bar",
      height: 380,
    },
    plotOptions: {
      bar: {
        barHeight: "100%",
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    colors: [
      "#33b2df",
      "#546E7A",
      "#d4526e",
      "#13d8aa",
      "#A5978B",
      "#2b908f",
      "#f9a3a4",
      "#90ee7e",
      "#f48024",
      "#69d2e7",
    ],
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#fff"],
      },
      formatter: function (val, opt) {
        let f = new Intl.NumberFormat("es-CL", {
          style: "currency",
          currency: "CLP",
          minimumFractionDigits: 0,
        });
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + f.format(val);
      },
      offsetX: 0,
      dropShadow: {
        enabled: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      labels: {
        formatter: function (value) {
          let f = new Intl.NumberFormat("es-CL", {
            style: "currency",
            currency: "CLP",
            minimumFractionDigits: 0,
          });
          return f.format(value);
        },
      },
      categories: stats.map((div) => div.name),
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">Dimensiones</Card.Title>
        <h6 className="card-subtitle text-muted">
          Inversión en capacitación por dimensión
        </h6>
      </Card.Header>
      <Card.Body>
        {stats.length > 0 ? (
          <div className="chart">
            <Chart options={options} series={series} type="bar" height="500" />
          </div>
        ) : (
          <NotDataCard />
        )}
      </Card.Body>
    </Card>
  );
};
export default InversionPorDimensionTwo;
