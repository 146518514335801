import axios from "../utils/axios";

export const fetchUtilsCompanySelect = async ({ selects }) => {
  const companies = selects.map((select) => select.value);
  const { data } = await axios.patch(
    "api/user/set-selected-companies",
    { companies },
    {
      headersd: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
};
