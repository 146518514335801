//import "devextreme/dist/css/dx.common.css";
//import "devextreme/dist/css/dx.light.css";
import React from "react";
import { useRoutes } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";

import "./i18n";
import routes from "./routes";

import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import ChartJsDefaults from "./utils/ChartJsDefaults";

import { AuthProvider } from "./contexts/JWTContext";
import "./App.scss";
const App = () => {
  const content = useRoutes(routes);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | Gestión de talento"
        defaultTitle="Gestión de talento"
      />
      <ThemeProvider>
        <SidebarProvider>
          <LayoutProvider>
            <ChartJsDefaults />
            <AuthProvider>{content}</AuthProvider>
          </LayoutProvider>
        </SidebarProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
