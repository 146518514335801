import { createContext, useState } from "react";
export const ShowChartsContext = createContext(null);
export const ShowChartsProvider = ({ children }) => {
  const [showSelect, setShowSelect] = useState(false);
  const [showChart, setShowChart] = useState([]);

  return (
    <ShowChartsContext.Provider
      value={{ showSelect, setShowSelect, showChart, setShowChart }}
    >
      {children}
    </ShowChartsContext.Provider>
  );
};
