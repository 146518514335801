import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
const EditModal = ({ show, toggleModal, submit, title, user, handleEdit }) => {
  return (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
      })}
    >
      <React.Fragment>
        <Modal show={show} onHide={() => toggleModal("editar")} centered>
          <Modal.Header closeButton>{title}</Modal.Header>
          <Modal.Body className="text-center m-3">
            <Container>
              <Row>
                <Col sm={6}>
                  <InputGroup>
                    <InputGroup.Text>Nombre</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="user"
                      placeholder="Ingrese el nombre"
                      value={user.name}
                      onChange={handleEdit}
                    />
                  </InputGroup>
                </Col>
                <Col sm={6}>
                  <InputGroup>
                    <InputGroup.Text>Correo</InputGroup.Text>
                    <Form.Control
                      type="email"
                      name="email"
                      value={user.email}
                      placeholder="Ingrese el correo"
                      onChange={handleEdit}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => toggleModal("editar")}>
              Cerrar
            </Button>
            <Button onClick={submit}>Editar</Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    </Formik>
  );
};

export default EditModal;
