import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, Row, Form } from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import useAuth from "../../hooks/useAuth";
import { useUserMutate } from "../../hooks/user";
import Alerts from "../../components/Alerts";
import { fetchUserUpdateLogo } from "../../services/user";

const PublicInfo = () => (
  <Card>
    <Card.Header>
      <Card.Title tag="h5" className="mb-0">
        Mi Información
      </Card.Title>
    </Card.Header>
    <Card.Body>
      <PersonalForm />
    </Card.Body>
  </Card>
);
const PersonalForm = () => {
  const { user } = useAuth();
  const { mutate, isError, isSuccess } = useUserMutate();
  const [logoFile, setLogoFile] = useState(null);
  return (
    <>
      {user && (
        <Formik
          initialValues={{
            name: user && user.name,
            email: user && user.email,
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Debe ingresar un email valido")
              .required("El campo es obligatorio"),
            name: Yup.string().required("El campo es obligatorio"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (logoFile) {
                let formData = new FormData();
                formData.append("logo", logoFile);
                await fetchUserUpdateLogo({
                  logo: formData,
                });
              }
              mutate({ request: values, userId: user.id });
            } catch (error) {
              console.log(error);
              const message = error.message || "Hubo un error inesperado.";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              {/* {errors.submit && (
                <Alert className="my-3" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )} */}
              <Row>
                <Col md="8">
                  <Form.Group className="mb-3">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                      size="lg"
                      type="text"
                      name="name"
                      value={values.name}
                      isInvalid={Boolean(touched.name && errors.name)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.name && (
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      size="lg"
                      type="email"
                      name="email"
                      value={values.email}
                      isInvalid={Boolean(touched.email && errors.email)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.email && (
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Alerts
                    failText="Hubo un error inesperado"
                    successText="Usuario editado correctamente"
                    isError={isError}
                    isSuccess={isSuccess}
                  />
                  {isSuccess && window.location.reload()}
                  <div className=" mt-3">
                    <Button
                      type="submit"
                      variant="primary"
                      size="lg"
                      disabled={isSubmitting}
                    >
                      Guardar cambios
                    </Button>
                  </div>
                </Col>
                <Col md="4">
                  <div className="text-center">
                    <img
                      alt="img"
                      src={
                        user && user.preference?.logo
                          ? user.preference.logo
                          : "/imgs/nologo.png"
                      }
                      className=" img-responsive mt-2"
                      width="200"
                      height="100"
                      style={{ objectFit: "contain", maxHeight: 128 }}
                    />
                    <div className="mt-2">
                      <Form.Control
                        type="file"
                        onChange={(e) => setLogoFile(e.target.files[0])}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

const Settings = () => {
  return (
    <React.Fragment>
      <Helmet title="Mi Perfil" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Mi Perfil</h1>

        <Row>
          <Col md="9" xl="10">
            <PublicInfo />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Settings;
