import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { fetchResultReport } from "../../services/result";
import { useQuery } from "react-query";
import BarResult from "../../components/result/ChartBar";
import { ChartBarPorcentaje } from "../../components/result/ChartBarPorcentaje";
import NotDataCard from "../pages/NotDataCard";
import { formatter, removeSignoPeso } from "../../utils/utils";

export default function ReporteResultado() {
  const { data: stats, isLoading } = useQuery(
    ["resultReport"],
    fetchResultReport
  );
  if (isLoading) {
    return <Spinner animation="border" />;
  }
  // const reagruparDivision = () => {
  //   let resultado_aprobacion = [];
  //   let resultado_reprobado = [];
  //   let division = [];
  //   if (stats !== null) {
  //     Object.keys(stats.resultsByDivision).map((keys) => {
  //       division.push(keys);
  //       resultado_aprobacion.push(stats.resultsByDivision[keys].APROBADO);
  //       resultado_reprobado.push(stats.resultsByDivision[keys].REPROBADO);
  //     });
  //   }
  /*if (stats !== null) {
      stats.resultsByDivision.map((d) => {
        if (d.resultado === "APROBADO") {
          resultado_aprobacion.push(d.count);
        } else if (d.resultado === "REPROBADO") {
          resultado_reprobado.push(d.count);
        }
      });
      if (resultado_aprobacion.length > resultado_reprobado.length) {
        let total = resultado_aprobacion.length - resultado_reprobado.length;
        for (let index = 0; index < total; index++) {
          console.log(index);
          resultado_reprobado.push(0);
        }
      } else if (resultado_aprobacion.length < resultado_reprobado.length) {
        let total = resultado_reprobado.length - resultado_aprobacion.length;
        for (let index = 0; index < total; index++) {
          console.log(index);
          resultado_reprobado.push(0);
        }
      }
      stats.resultsByDivision.map((d) => {
        if (!division.find((e) => e === d.division)) {
          division.push(d.division);
        }
        if (!resultado.find((e) => e.name === d.resultado)) {
          if (d.resultado === "APROBADO") {
            resultado.push({
              name: d.resultado,
              data: resultado_aprobacion,
            });
          } else if (d.resultado === "REPROBADO") {
            resultado.push({
              name: d.resultado,
              data: resultado_reprobado,
            });
          }
        }
      });
    }
    return {
      resultado,
      division,
    };*/
  // }
  const reagrupar = () => {
    let mujeres = [];
    let hombres = [];
    if (stats !== null) {
      stats.resultsByGender.forEach((d) => {
        if (d.genero === "Mujer") {
          mujeres.push(d.count);
        } else {
          hombres.push(d.count);
        }
      });
      if (mujeres.length < 2) {
        mujeres.push(0);
      }
      if (hombres.length < 2) {
        hombres.push(0);
      }
    }
    return {
      mujeres,
      hombres,
    };
  };
  const datosGenero = reagrupar();
  return (
    <React.Fragment>
      <Helmet title="Blank Page" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Resultados</h1>
        <Row>
          {stats !== null && stats.resultstotals.length > 0 ? (
            <>
              <Col md="6" xl="6" className="d-flex text-center">
                <Card className="flex-fill">
                  <Card.Body className=" py-4">
                    <div className="d-flex align-items-start">
                      <div className="flex-grow-1">
                        <h3 className="mb-2">
                          {removeSignoPeso(
                            formatter.format(stats.resultstotals[0].count)
                          )}
                        </h3>
                        <p className="mb-2">{stats.resultstotals[0].name}</p>
                      </div>
                      <div className="d-inline-block ms-3"></div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" xl="6" className="d-flex text-center">
                <Card className="flex-fill">
                  <Card.Body className=" py-4">
                    <div className="d-flex align-items-start">
                      <div className="flex-grow-1">
                        <h3 className="mb-2">
                          {removeSignoPeso(
                            formatter.format(stats.resultstotals[1].count)
                          )}
                        </h3>
                        <p className="mb-2">{stats.resultstotals[1].name}</p>
                      </div>
                      <div className="d-inline-block ms-3"></div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </>
          ) : (
            <NotDataCard />
          )}
          <Col md={12}>
            <Card>
              <Card.Header>
                <Card.Title tag="h5" className="mb-0"></Card.Title>
              </Card.Header>
              <Card.Body>
                {/*stats !== null && stats.resultsByDivision.length > 0 && (
                  <BarResultDivision data={datosDivision} />
                )*/}
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title tag="h5" className="mb-0"></Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={6}>
                    <BarResult data={datosGenero} />
                  </Col>
                  <Col md={6}>
                    {stats !== null && (
                      <ChartBarPorcentaje data={stats.results_percentages} />
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
