import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import {
  fetchAdminCompany,
  fetchAdminImportParticipations,
  fetchAdminDivision,
} from "../services/admin";

export const useAdminImportParticipation = () => {
  return useMutation(fetchAdminImportParticipations);
};
export const useAdminCompany = () => {
  const [company, setCompany] = useState([]);
  useEffect(() => {
    setCompany(fetchAdminCompany());
  }, []);

  return { company };
};

export const useAdminDivision = () => {
  return useMutation(fetchAdminDivision);
};
