import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";
import NotDataCard from "../../pages/NotDataCard";

const InversionPorDimensionPie = ({ stats }) => {
  console.log(stats);
  const series = stats.map((div) => div.sum);
  const options = {
    chart: {
      width: 500,
      type: "pie",
    },
    labels: stats.map((div) => div.name),
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">Dimensiones</Card.Title>
        <h6 className="card-subtitle text-muted">
          Inversión en capacitación por dimensión
        </h6>
      </Card.Header>
      <Card.Body>
        {stats.length > 0 ? (
          <div className="chart">
            <Chart options={options} series={series} type="pie" height="500" />
          </div>
        ) : (
          <NotDataCard />
        )}
      </Card.Body>
    </Card>
  );
};
export default InversionPorDimensionPie;
