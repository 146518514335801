import React from "react";

import { Card } from "react-bootstrap";

import ReactApexChart from "react-apexcharts";
import NotDataCard from "../../pages/pages/NotDataCard";
import { formatter, removeSignoPeso } from "../../utils/utils";

const BarResult = ({ data }) => {
  const state = {
    series: [
      {
        name: "Mujeres",
        data: data.mujeres,
      },
      {
        name: "Hombres",
        data: data.hombres,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: "100%",
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (value) {
          return removeSignoPeso(formatter.format(value));
        },
        offsetY: -15,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      stroke: {
        width: 2,
      },
      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          formatter: function (value) {
            return removeSignoPeso(formatter.format(value));
          },
        },
        categories: ["Aprobado", "Reprobado"],
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
        },
      },
    },
  };
  console.log(data);
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">Resultado de capacitación por genero </Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="chart">
          {data.mujeres.length > 0 &&
          data.hombres.length > 0 &&
          data.mujeres[0] > 0 &&
          data.hombres[0] > 0 ? (
            <ReactApexChart
              options={state.options}
              series={state.series}
              type="bar"
              height={380}
            />
          ) : (
            <NotDataCard />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};
export default BarResult;
