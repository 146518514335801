import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";
import NotDataCard from "../../pages/NotDataCard";

const InversionPorDimensionDonut = ({ stats }) => {
  const series = stats.map((div) => div.sum);
  const options = {
    chart: {
      width: 500,
      type: "pie",
    },
    labels: stats.map((div) => div.name),
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10,
      },
    },
    grid: {
      padding: {
        bottom: -80,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">Dimensiones</Card.Title>
        <h6 className="card-subtitle text-muted">
          Inversión en capacitación por dimensión
        </h6>
      </Card.Header>
      {stats.length > 0 ? (
        <Card.Body>
          <div className="chart">
            <Chart options={options} series={series} type="pie" height="500" />
          </div>
        </Card.Body>
      ) : (
        <NotDataCard />
      )}
    </Card>
  );
};
export default InversionPorDimensionDonut;
