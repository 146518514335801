import React from "react";
import { Image } from "react-bootstrap";
import aviso from "../../assets/img/aviso.png";
const NotDataCard = () => (
  <div className="text-center">
    <Image src={aviso} width="50" />
    <p style={{ fontSize: "20px" }}>
      Debes Ingresar datos para poder ver la informacion
    </p>
  </div>
);

export default NotDataCard;
