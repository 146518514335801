// ExpandableRows demo
const subRows = [
  {
    name: "Shad Decker",
    position: "Regional Director",
    office: "Edinburgh",
    age: 51,
    startDate: "2008/11/13",
    salary: "$183,000",
  },
  {
    name: "Michael Bruce",
    position: "Javascript Developer",
    office: "Singapore",
    age: 29,
    startDate: "2011/06/27",
    salary: "$183,000",
  },
  {
    name: "Donna Snider",
    position: "Customer Support",
    office: "New York",
    age: 27,
    startDate: "2011/01/25",
    salary: "$112,000",
  },
];

const tableData = [
  {
    name: "Tiger Nixon",
    position: "System Architect",
    office: "Edinburgh",
    age: 61,
    startDate: "2011/04/25",
    salary: "$320,800",
    subRows: subRows,
  },
  {
    name: "Garrett Winters",
    position: "Accountant",
    office: "Tokyo",
    age: 63,
    startDate: "2011/07/25",
    salary: "$170,750",
    subRows: subRows,
  },
  {
    name: "Ashton Cox",
    position: "Junior Technical Author",
    office: "San Francisco",
    age: 66,
    startDate: "2009/01/12",
    salary: "$86,000",
    subRows: subRows,
  },
  {
    name: "Cedric Kelly",
    position: "Senior Javascript Developer",
    office: "Edinburgh",
    age: 22,
    startDate: "2012/03/29",
    salary: "$433,060",
    subRows: subRows,
  },
  {
    name: "Airi Satou",
    position: "Accountant",
    office: "Tokyo",
    age: 33,
    startDate: "2008/11/28",
    salary: "$162,700",
    subRows: subRows,
  },
  {
    name: "Brielle Williamson",
    position: "Integration Specialist",
    office: "New York",
    age: 61,
    startDate: "2012/12/02",
    salary: "$372,000",
    subRows: subRows,
  },
  {
    name: "Herrod Chandler",
    position: "Sales Assistant",
    office: "San Francisco",
    age: 59,
    startDate: "2012/08/06",
    salary: "$137,500",
    subRows: subRows,
  },
  {
    name: "Rhona Davidson",
    position: "Integration Specialist",
    office: "Tokyo",
    age: 55,
    startDate: "2010/10/14",
    salary: "$327,900",
    subRows: subRows,
  },
  {
    name: "Colleen Hurst",
    position: "Javascript Developer",
    office: "San Francisco",
    age: 39,
    startDate: "2009/09/15",
    salary: "$205,500",
    subRows: subRows,
  },
  {
    name: "Sonya Frost",
    position: "Software Engineer",
    office: "Edinburgh",
    age: 23,
    startDate: "2008/12/13",
    salary: "$103,600",
    subRows: subRows,
  },
  {
    name: "Jena Gaines",
    position: "Office Manager",
    office: "London",
    age: 30,
    startDate: "2008/12/19",
    salary: "$90,560",
    subRows: subRows,
  },
  {
    name: "Quinn Flynn",
    position: "Support Lead",
    office: "Edinburgh",
    age: 22,
    startDate: "2013/03/03",
    salary: "$342,000",
    subRows: subRows,
  },
  {
    name: "Charde Marshall",
    position: "Regional Director",
    office: "San Francisco",
    age: 36,
    startDate: "2008/10/16",
    salary: "$470,600",
    subRows: subRows,
  },
  {
    name: "Haley Kennedy",
    position: "Senior Marketing Designer",
    office: "London",
    age: 43,
    startDate: "2012/12/18",
    salary: "$313,500",
    subRows: subRows,
  },
  {
    name: "Tatyana Fitzpatrick",
    position: "Regional Director",
    office: "London",
    age: 19,
    startDate: "2010/03/17",
    salary: "$385,750",
    subRows: subRows,
  },
  {
    name: "Michael Silva",
    position: "Marketing Designer",
    office: "London",
    age: 66,
    startDate: "2012/11/27",
    salary: "$198,500",
    subRows: subRows,
  },
  {
    name: "Paul Byrd",
    position: "Chief Financial Officer (CFO)",
    office: "New York",
    age: 64,
    startDate: "2010/06/09",
    salary: "$725,000",
    subRows: subRows,
  },
  {
    name: "Gloria Little",
    position: "Systems Administrator",
    office: "New York",
    age: 59,
    startDate: "2009/04/10",
    salary: "$237,500",
    subRows: subRows,
  },
  {
    name: "Bradley Greer",
    position: "Software Engineer",
    office: "London",
    age: 41,
    startDate: "2012/10/13",
    salary: "$132,000",
    subRows: subRows,
  },
  {
    name: "Dai Rios",
    position: "Personnel Lead",
    office: "Edinburgh",
    age: 35,
    startDate: "2012/09/26",
    salary: "$217,500",
    subRows: subRows,
  },
  {
    name: "Jenette Caldwell",
    position: "Development Lead",
    office: "New York",
    age: 30,
    startDate: "2011/09/03",
    salary: "$345,000",
    subRows: subRows,
  },
  {
    name: "Yuri Berry",
    position: "Chief Marketing Officer (CMO)",
    office: "New York",
    age: 40,
    startDate: "2009/06/25",
    salary: "$675,000",
    subRows: subRows,
  },
  {
    name: "Caesar Vance",
    position: "Pre-Sales Support",
    office: "New York",
    age: 21,
    startDate: "2011/12/12",
    salary: "$106,450",
    subRows: subRows,
  },
  {
    name: "Doris Wilder",
    position: "Sales Assistant",
    office: "Sidney",
    age: 23,
    startDate: "2010/09/20",
    salary: "$85,600",
    subRows: subRows,
  },
  {
    name: "Angelica Ramos",
    position: "Chief Executive Officer (CEO)",
    office: "London",
    age: 47,
    startDate: "2009/10/09",
    salary: "$1,200,000",
    subRows: subRows,
  },
  {
    name: "Gavin Joyce",
    position: "Developer",
    office: "Edinburgh",
    age: 42,
    startDate: "2010/12/22",
    salary: "$92,575",
    subRows: subRows,
  },
  {
    name: "Jennifer Chang",
    position: "Regional Director",
    office: "Singapore",
    age: 28,
    startDate: "2010/11/14",
    salary: "$357,650",
    subRows: subRows,
  },
  {
    name: "Brenden Wagner",
    position: "Software Engineer",
    office: "San Francisco",
    age: 28,
    startDate: "2011/06/07",
    salary: "$206,850",
    subRows: subRows,
  },
  {
    name: "Fiona Green",
    position: "Chief Operating Officer (COO)",
    office: "San Francisco",
    age: 48,
    startDate: "2010/03/11",
    salary: "$850,000",
    subRows: subRows,
  },
  {
    name: "Shou Itou",
    position: "Regional Marketing",
    office: "Tokyo",
    age: 20,
    startDate: "2011/08/14",
    salary: "$163,000",
    subRows: subRows,
  },
  {
    name: "Michelle House",
    position: "Integration Specialist",
    office: "Sidney",
    age: 37,
    startDate: "2011/06/02",
    salary: "$95,400",
    subRows: subRows,
  },
  {
    name: "Suki Burks",
    position: "Developer",
    office: "London",
    age: 53,
    startDate: "2009/10/22",
    salary: "$114,500",
    subRows: subRows,
  },
  {
    name: "Prescott Bartlett",
    position: "Technical Author",
    office: "London",
    age: 27,
    startDate: "2011/05/07",
    salary: "$145,000",
    subRows: subRows,
  },
  {
    name: "Gavin Cortez",
    position: "Team Leader",
    office: "San Francisco",
    age: 22,
    startDate: "2008/10/26",
    salary: "$235,500",
    subRows: subRows,
  },
  {
    name: "Martena Mccray",
    position: "Post-Sales support",
    office: "Edinburgh",
    age: 46,
    startDate: "2011/03/09",
    salary: "$324,050",
    subRows: subRows,
  },
  {
    name: "Unity Butler",
    position: "Marketing Designer",
    office: "San Francisco",
    age: 47,
    startDate: "2009/12/09",
    salary: "$85,675",
    subRows: subRows,
  },
  {
    name: "Howard Hatfield",
    position: "Office Manager",
    office: "San Francisco",
    age: 51,
    startDate: "2008/12/16",
    salary: "$164,500",
    subRows: subRows,
  },
  {
    name: "Hope Fuentes",
    position: "Secretary",
    office: "San Francisco",
    age: 41,
    startDate: "2010/02/12",
    salary: "$109,850",
    subRows: subRows,
  },
  {
    name: "Vivian Harrell",
    position: "Financial Controller",
    office: "San Francisco",
    age: 62,
    startDate: "2009/02/14",
    salary: "$452,500",
    subRows: subRows,
  },
  {
    name: "Timothy Mooney",
    position: "Office Manager",
    office: "London",
    age: 37,
    startDate: "2008/12/11",
    salary: "$136,200",
    subRows: subRows,
  },
  {
    name: "Jackson Bradshaw",
    position: "Director",
    office: "New York",
    age: 65,
    startDate: "2008/09/26",
    salary: "$645,750",
    subRows: subRows,
  },
  {
    name: "Olivia Liang",
    position: "Support Engineer",
    office: "Singapore",
    age: 64,
    startDate: "2011/02/03",
    salary: "$234,500",
    subRows: subRows,
  },
  {
    name: "Bruno Nash",
    position: "Software Engineer",
    office: "London",
    age: 38,
    startDate: "2011/05/03",
    salary: "$163,500",
    subRows: subRows,
  },
  {
    name: "Sakura Yamamoto",
    position: "Support Engineer",
    office: "Tokyo",
    age: 37,
    startDate: "2009/08/19",
    salary: "$139,575",
    subRows: subRows,
  },
  {
    name: "Thor Walton",
    position: "Developer",
    office: "New York",
    age: 61,
    startDate: "2013/08/11",
    salary: "$98,540",
    subRows: subRows,
  },
  {
    name: "Finn Camacho",
    position: "Support Engineer",
    office: "San Francisco",
    age: 47,
    startDate: "2009/07/07",
    salary: "$87,500",
    subRows: subRows,
  },
  {
    name: "Serge Baldwin",
    position: "Data Coordinator",
    office: "Singapore",
    age: 64,
    startDate: "2012/04/09",
    salary: "$138,575",
    subRows: subRows,
  },
  {
    name: "Zenaida Frank",
    position: "Software Engineer",
    office: "New York",
    age: 63,
    startDate: "2010/01/04",
    salary: "$125,250",
    subRows: subRows,
  },
  {
    name: "Zorita Serrano",
    position: "Software Engineer",
    office: "San Francisco",
    age: 56,
    startDate: "2012/06/01",
    salary: "$115,000",
    subRows: subRows,
  },
  {
    name: "Jennifer Acosta",
    position: "Junior Javascript Developer",
    office: "Edinburgh",
    age: 43,
    startDate: "2013/02/01",
    salary: "$75,650",
    subRows: subRows,
  },
  {
    name: "Cara Stevens",
    position: "Sales Assistant",
    office: "New York",
    age: 46,
    startDate: "2011/12/06",
    salary: "$145,600",
    subRows: subRows,
  },
  {
    name: "Hermione Butler",
    position: "Regional Director",
    office: "London",
    age: 47,
    startDate: "2011/03/21",
    salary: "$356,250",
    subRows: subRows,
  },
  {
    name: "Lael Greer",
    position: "Systems Administrator",
    office: "London",
    age: 21,
    startDate: "2009/02/27",
    salary: "$103,500",
    subRows: subRows,
  },
  {
    name: "Jonas Alexander",
    position: "Developer",
    office: "San Francisco",
    age: 30,
    startDate: "2010/07/14",
    salary: "$86,500",
    subRows: subRows,
  },
  {
    name: "Shad Decker",
    position: "Regional Director",
    office: "Edinburgh",
    age: 51,
    startDate: "2008/11/13",
    salary: "$183,000",
    subRows: subRows,
  },
  {
    name: "Michael Bruce",
    position: "Javascript Developer",
    office: "Singapore",
    age: 29,
    startDate: "2011/06/27",
    salary: "$183,000",
    subRows: subRows,
  },
  {
    name: "Donna Snider",
    position: "Customer Support",
    office: "New York",
    age: 27,
    startDate: "2011/01/25",
    salary: "$112,000",
    subRows: subRows,
  },
];

const tableColumns = [
  //{
  //  Header: "Nombre Completo",
  //  accessor: "nombre_completo",
  //},
  //{
  //  Header: "Diccionario",
  //  accessor: "course_category",
  //},
  //{
  //  Header: "Familia O Programa",
  //  accessor: "course_area",
  //},
  {
    Header: "Nombre de curso, taller o programa",
    accessor: "course_name",
  },
  {
    Header: "Nº De Horas",
    accessor: "course_hours",
  },
  {
    Header: "Valor por participante (costo sence)",
    accessor: "costo_sence",
    Cell: (props) => <div>{props.value.toLocaleString("es-CL")}</div>,
  },
  {
    Header: "Valor por participante (costo empresa)",
    accessor: "costo_empresa",
    Cell: (props) => <div>{props.value.toLocaleString("es-CL")}</div>,
  },
  {
    Header: "Valor total por participante",
    accessor: "costo_total",
    Cell: (props) => <div>{props.value.toLocaleString("es-CL")}</div>,
  },
  {
    Header: "RUT Proveedor",
    accessor: "provider_rut",
  },
  {
    Header: "Nombre Proveedor",
    accessor: "provider_name",
  },
  {
    Header: "Fecha Inicio",
    accessor: "course_start",
  },
  {
    Header: "Fecha Término",
    accessor: "course_end",
  },
  {
    Header: "Estado",
    accessor: "participation_status",
  },
  {
    Header: "Modalidad",
    accessor: "course_modality",
  },
];

export { tableData, tableColumns };
