import React, { Fragment } from "react";
import Chart from "react-apexcharts";
import { Card, Spinner } from "react-bootstrap";
import usePalette from "../../../../hooks/usePalette";
import { fetchCapacitadosPorDimension } from "../../../../services/capacitaciones";
import { useQuery } from "react-query";
import NotData from "../../../pages/NotData";
import { formatter, removeSignoPeso } from "../../../../utils/utils";
const CapacitadosPorDimension = () => {
  const palette = usePalette();
  const {
    data: stats,
    error,
    isLoading,
  } = useQuery(["capacitadosPorDimension"], fetchCapacitadosPorDimension);
  if (isLoading) {
    return <Spinner animation="border" />;
  }
  if (stats.length === 0 || !stats) {
    return (
      <NotData title="No existen datos en el grafico de Cantidad de trabajadores capacitados por dimensión" />
    );
  }
  if (error) {
    return <p>Hubo un error inesperado</p>;
  }
  const data = [
    {
      name: "Hombres",
      data: stats
        .filter((item) => item.gender === 1)
        .map((item) => item.participations),
    },
    {
      name: "Mujeres",
      data: stats
        .filter((item) => item.gender === 2)
        .map((item) => item.participations),
    },
  ];

  const options = {
    chart: {
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: [...new Set(stats.map((item) => item.division))],
      labels: {
        formatter: function (val) {
          return val + " participantes";
        },
      },
    },
    dataLabels: {
      formatter: function (value) {
        return removeSignoPeso(formatter.format(value));
      },
      offsetY: -1,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
      labels: {
        maxWidth: 300,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " participantes";
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
    colors: [
      palette.primary,
      palette.success,
      palette.warning,
      palette.danger,
      palette.info,
    ],
  };

  return (
    <Card>
      {stats.length > 0 && (
        <Fragment>
          <Card.Header>
            <Card.Title tag="h5">Dimensiones</Card.Title>
            <h6 className="card-subtitle text-muted">
              Cantidad de trabajadores capacitados por dimensión
            </h6>
          </Card.Header>
          <Card.Body>
            <div className="chart">
              <Chart options={options} series={data} type="bar" height="500" />
            </div>
          </Card.Body>
        </Fragment>
      )}
    </Card>
  );
};

export default CapacitadosPorDimension;
