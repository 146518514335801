import React from "react";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import SelectorExample from "../../components/SelectorExample";
import Header from "./Header";
import { fetchDashboardData } from "../../services/dashboard";
import Statistics from "./Statistics";
import NotData from "../pages/NotData";
import { TipoCurso } from "../../components/dashboard/TipoCurso";

const Default = () => {
  const { data, error, isLoading } = useQuery(
    ["dashboardData"],
    fetchDashboardData
  );
  if (isLoading) {
    return <Spinner animation="border" />;
  }
  if (!data) {
    return <NotData title="No existen datos para visualizar" />;
  }
  if (error) {
    return <p>Hubo un error inesperado</p>;
  }
  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Container fluid className="p-0">
        <Header />
        <Statistics
          budget={data?.budget}
          approvalRate={data?.approvalRate}
          enrolled={data?.enrolled}
        />
        <Row>
          <Col md="6">
            <SelectorExample />
          </Col>
          <Col md="6">
            {data !== null && (
              <TipoCurso data={data?.participationsByCourseModality} />
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Default;
