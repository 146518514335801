import axios from "../utils/axios";

export const fetchDashboardData = async () => {
  try {
    const { data } = await axios.get("api/dashboard?company_id=" + 1);
    return data.data;
  } catch (error) {
    console.log("error fetchDashboardData", error);
    return null;
  }
};
