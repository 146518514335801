import { useMutation, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { fetchUtilsCompanySelect } from "../services/utils";

export const UseUtilsCompanySelect = () => {
  const queryClient = useQueryClient();
  const data = useMutation(fetchUtilsCompanySelect);
  let navigate = useLocation();
  if (navigate.pathname === "/budget/reports-dimension") {
    queryClient.refetchQueries(["dimensiones"]);
    queryClient.refetchQueries(["inversionPorDivision"]);
    //queryClient.refetchQueries(["dashboardData"]);
    //queryClient.refetchQueries(["inscritosAlmenosUnCurso"]);
    //queryClient.refetchQueries(["averageHoursByDivision"]);
    //queryClient.refetchQueries(["capacitadosPorDimension"]);
    //queryClient.refetchQueries(["maleFemale"]);
  } else if (navigate.pathname === "/") {
    queryClient.refetchQueries(["dashboardData"]);
    queryClient.refetchQueries(["inscritosAlmenosUnCurso"]);
  }
  return data;
};
