import React from "react";
import { Outlet } from "react-router-dom";

import Main from "../components/Main";
import Settings from "../components/Settings";

const Auth = ({ children }) => (
  <React.Fragment>
    <Main>
      {children}
      <Outlet />
    </Main>
    <Settings />
  </React.Fragment>
);

export default Auth;
