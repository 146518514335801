import React, { useEffect, useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import Select from "react-select";
import Alerts from "../../../components/Alerts";
import {
  useAdminImportParticipation,
  useAdminCompany,
} from "../../../hooks/useAdmin";
import { Col, Row } from "react-bootstrap";
import { fetchCompanies } from "../../../services/admin";
const CargarInformacionPage = () => {
  const [importFile, setImportFile] = useState();
  const [availableCompanies, setAvailableCompanies] = useState(false);
  const [stateCompany, setStateCompany] = useState();
  const initAvailableCompanies = async () => {
    const res = await fetchCompanies();
    setAvailableCompanies(
      res.map((item) => ({ value: item.id, label: item.name }))
    );
  };

  useEffect(() => {
    initAvailableCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    mutate: importParticipations,
    isSuccess,
    isError,
    isLoading,
    error,
  } = useAdminImportParticipation();
  const handleImport = async () => {
    let formData = new FormData();
    formData.append("participations", importFile);
    formData.append("company_id", stateCompany);
    importParticipations({ request: formData });
  };
  const handleCompanySelect = async (e) => {
    setStateCompany(e.value);
  };
  return (
    <Row className="d-flex justify-content-center">
      <Col md="6" xl="6" className="">
        <Form.Group className="mb-3">
          <Form.Label>Importar Datos</Form.Label>
          <Form.Control
            type="file"
            onChange={(e) => setImportFile(e.target.files[0])}
          />
          <Form.Group className="mb-12">
            <Form.Label>Empresa</Form.Label>
            <Select
              className="react-select-container w-100"
              classNamePrefix="select"
              options={availableCompanies}
              onChange={handleCompanySelect}
            />
          </Form.Group>
          <Button
            onClick={handleImport}
            style={{ width: "100%" }}
            className="mt-3"
            disabled={!importFile}
          >
            Importar
          </Button>
        </Form.Group>
      </Col>
      <Col md="12" xl="12" className="text-center">
        {isLoading && <Spinner animation="border" />}
      </Col>
      <Alerts
        failedIf={isError}
        successIf={isSuccess}
        failedText={error?.message ?? "Ocurrió un error"}
        succesText="Archivo importado correctamente"
      />
    </Row>
  );
};

export default CargarInformacionPage;
