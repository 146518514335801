import React from "react";
import { Card, Col, Form, Pagination, Row, Accordion } from "react-bootstrap";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { GlobalFilter, fuzzyTextFilterFn } from "../../utils/GlobalFilter";

const PaginationAccordion = ({
  title,
  currentPage,
  lastPage,
  columns,
  data,
  toggleModal,
  useHook,
}) => {
  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const {
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: { pageIndex: 0, globalFilter: "" },
    },
    useGlobalFilter,
    usePagination
  );
  const pageNow = currentPage ?? pageIndex + 1;
  const totalPages = lastPage ?? pageSize;

  const { refresh } = useHook();

  const toPage = !lastPage
    ? gotoPage
    : (page) => {
        try {
          refresh(page);
        } catch (error) {
          console.error(error);
        }
      };

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title tag="h5">
            {title}
            <div className="card-actions float-end">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                useHook={useHook}
              />
            </div>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Accordion defaultActiveKey="0">
            {data.map((d, key) => {
              return (
                <Accordion.Item eventKey={key}>
                  <Accordion.Header className="text-center">
                    <Col md="4">{d.course_name}</Col>
                    <Col md="2" className="text-center">
                      <b style={{ fontSize: "15px" }}>
                        {d.participation_status}
                      </b>
                    </Col>
                    <Col md="3" className="text-end">
                      <b style={{ fontSize: "15px" }}>{d.course_modality}</b>
                    </Col>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md="6">
                        <p>
                          N° De Horas:
                          <b style={{ fontSize: "20px" }}> {d.course_hours}</b>
                        </p>
                        <p>
                          Valor por participante (costo sence):
                          <b style={{ fontSize: "20px" }}>
                            {d.costo_sence.toLocaleString("es-CL", {
                              style: "currency",
                              currency: "CLP",
                            })}
                          </b>
                        </p>
                        <p>
                          Valor por participante (costo empresa):
                          <b style={{ fontSize: "20px" }}>
                            {d.costo_empresa.toLocaleString("es-CL", {
                              style: "currency",
                              currency: "CLP",
                            })}
                          </b>
                        </p>
                        <p>
                          Valor total por participante:
                          <b style={{ fontSize: "20px" }}>
                            {d.costo_total.toLocaleString("es-CL", {
                              style: "currency",
                              currency: "CLP",
                            })}
                          </b>
                        </p>
                        <p>
                          RUT Proveedor:
                          <b style={{ fontSize: "20px" }}> {d.provider_rut}</b>
                        </p>
                      </Col>
                      <Col md="6">
                        <p>
                          Nombre Proveedor:
                          <b style={{ fontSize: "20px" }}> {d.provider_name}</b>
                        </p>
                        <p>
                          Fecha Inicio:
                          <b style={{ fontSize: "20px" }}> {d.course_start}</b>
                        </p>
                        <p>
                          Fecha Término:
                          <b style={{ fontSize: "20px" }}> {d.course_end}</b>
                        </p>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
          {/*<Table responsive striped bordered {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, i) => {
                return (
                  <tr key={i}>
                    {headerGroup.headers.map((column, i) => {
                      return (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      );
                    })}
                    {toggleModal && <th>Acciones</th>}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                    {toggleModal && (
                      <td className="table-action">
                        <Edit2
                          className="align-middle me-1"
                          size={18}
                          onClick={() => toggleModal("editar", row.cells)}
                        />
                        <Trash
                          className="align-middle"
                          size={18}
                          onClick={() => toggleModal("delete", row.cells)}
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>*/}

          <Row>
            <Col md="6">
              <span className="mx-2">
                Página{" "}
                <strong>
                  {pageNow} de {totalPages}
                </strong>
              </span>
              <span className="ms-3 me-2">Ir a página:</span>
              <Form.Control
                className="d-inline-block"
                min={1}
                type="number"
                defaultValue={pageNow}
                onChange={(e) => {
                  const page =
                    e.target.value > 0 && e.target.value <= totalPages
                      ? Number(e.target.value)
                      : 1;
                  toPage(page);
                }}
                style={{ width: "75px" }}
              />
            </Col>
            <Col md="6">
              <Pagination className="float-end">
                <Pagination.First
                  onClick={() => toPage(1)}
                  disabled={!canPreviousPage}
                />
                <Pagination.Prev
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />
                <Pagination.Next
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
                <Pagination.Last
                  onClick={() => toPage(totalPages)}
                  disabled={!canNextPage}
                />
              </Pagination>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default PaginationAccordion;
