import React from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";
import InscritosAlmenosUnCurso from "./charts/InscritosAlmenosUnCurso";
import MaleFemale from "./charts/MaleFemale";
import CapacitadosPorDimension from "./charts/CapacitadosPorDimension";
import HoursMaleFemale from "./charts/HoursMaleFemale";
import AverageHoursMaleFemale from "./charts/AverageHoursMaleFemale";

const Reportes = () => {
  return (
    <React.Fragment>
      <Helmet title="Reportes" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">
          Reportes de capacitaciones (por rut, horas y género)
        </h1>
        <Row>
          <Col lg="6">
            <InscritosAlmenosUnCurso />
          </Col>
          <Col lg="6">
            <MaleFemale />
          </Col>
          <Col lg="12">
            <CapacitadosPorDimension />
          </Col>
          <Col lg="6">
            <HoursMaleFemale />
          </Col>
          <Col lg="6">
            <AverageHoursMaleFemale />
          </Col>
          {/*<Col lg="12">
            <AverageHouseByDivision />
          </Col>*/}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Reportes;
