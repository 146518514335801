const tableColumnsUser = [
  {
    Header: "id",
    accessor: "id",
  },
  {
    Header: "Nombre",
    accessor: "name",
  },
  {
    Header: "correo",
    accessor: "email",
  },
  {
    Header: "rol",
    accessor: "role.name",
  },
];

export { tableColumnsUser };
