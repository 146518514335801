export const proyectos = [
  {
    name: "EFOBECH TRADICIONAL",
    subProyecto: {
      name: "EFOBECH TRADICIONAL",
      aprobado: 103000000,
      gasto: 53000000,
      saldo: 44000000,
    },
  },
  {
    name: "CAPACITACIÓN DEL PERSONAL",
    subProyecto: {
      name: "GERENCIA DIVISION OPERACION Y SISTEMAS",
      aprobado: 157000000,
      gasto: 59000000,
      saldo: 108000000,
    },
  },
  {
    name: "",
    subProyecto: {
      name: "GERENCIA GENERAL DE FINANZAS",
      aprobado: 17130000,
      gasto: 5289000,
      saldo: 11841000,
    },
  },
  {
    name: "",
    subProyecto: {
      name: "CURSOS PERFECCIONAMIENTO Y DESARROLLO",
      aprobado: 10000000,
      gasto: 8000000,
      saldo: 2000000,
    },
  },
  {
    name: "",
    subProyecto: {
      name: "GERENCIA DIVISIONES SUCURSALES",
      aprobado: 40000000,
      gasto: 15000000,
      saldo: 25000000,
    },
  },
  {
    name: "INDUCCIÓN DEL PERSONAL",
    subProyecto: {
      name: "INDUCCIÓN DEL PERSONAL",
      aprobado: 50000000,
      gasto: 10000000,
      saldo: 40000000,
    },
  },
  {
    name: "PLATAFORMA DE NEGOCIOS",
    subProyecto: {
      name: "PLATAFORMA EMPRESAS E INSTITUCIONES",
      aprobado: 120000000,
      gasto: 50000000,
      saldo: 70000000,
    },
  },
  {
    name: "",
    subProyecto: {
      name: "PLATAFORMA PERSONAS Y SUCURSALES",
      aprobado: 20000000,
      gasto: 3000000,
      saldo: 17000000,
    },
  },
  {
    name: "PLATAFORMA DESARROLLO DE PERSONAS",
    subProyecto: {
      name: "PLATAFORMA DESARROLLO DE PERSONAS",
      aprobado: 5000000,
      gasto: 1000000,
      saldo: 4000000,
    },
  },
  {
    name: "BECAS",
    subProyecto: {
      name: "BECAS",
      aprobado: 9000000,
      gasto: 7000000,
      saldo: 2000000,
    },
  },
];
