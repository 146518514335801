import React from "react";
import { Helmet } from "react-helmet-async";
import { Col, Row } from "react-bootstrap";

import SignIn from "../../components/auth/SignIn";
import avatarBanotic from "../../assets/img/avatars/avatarBanotic.png";
const SignInPage = () => (
  <React.Fragment>
    <Helmet title="Iniciar Sesión" />
    <Row style={{ height: "100vh" }}>
      <Col md="6">
        <div
          style={{
            maxWidth: 300,
            margin: "auto",
            marginTop: "5em",
            padding: "1em",
          }}
        >
          <div className="text-center mt-4">
            <h2>Bienvenido</h2>
            <p className="lead">Inicie sesión para continuar</p>
          </div>
          <div className="text-center">
            <img
              src={avatarBanotic}
              alt="Chris Wood"
              className="img-fluid rounded-circle"
              width="132"
              height="132"
            />
          </div>
          <SignIn />
        </div>
      </Col>
      <Col md="6">
        <img
          src="https://cdn.discordapp.com/attachments/955806186622554142/1007423542784180224/scott-graham-5fNmWej4tAA-unsplash.jpg"
          alt="img"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Col>
    </Row>
  </React.Fragment>
);

export default SignInPage;
