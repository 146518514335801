import React from "react";
import { Button, Modal } from "react-bootstrap";

const CreateModal = ({ show, toggleModal }) => {
  return (
    <React.Fragment>
      <Modal show={show} onHide={() => toggleModal(1)} centered>
        <Modal.Header closeButton>Centered modal</Modal.Header>
        <Modal.Body className="text-center m-3">
          <p className="mb-0">
            Use Bootstrap's JavaScript modal plugin to add dialogs to your site
            for lightboxes, user notifications, or completely custom content.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => toggleModal(1)}>
            Close
          </Button>{" "}
          <Button onClick={() => toggleModal(1)}>Save changes</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default CreateModal;
