import { Col, Row } from "react-bootstrap";
import ChartPie from "../../../components/ChartPie";
import DefaultTable from "../../../components/DefaultTable";
import { formatter } from "../../../utils/utils";
import AprobadoSubProyecto from "../charts/AprobadoSubProyecto";

import { proyectos } from "./data";

const ReportesPorProyecto = () => {
  const columns = [
    "Nombre de proyecto",
    "Nombre de subproyecto",
    "Aprobado 2021",
    "Gasto al 21/12/2021",
    "Saldo",
  ];
  const totals = () => {
    let aprobado = 0;
    let gasto = 0;
    let saldo = 0;
    proyectos.forEach((p) => {
      aprobado += p.subProyecto.aprobado;
      gasto += p.subProyecto.gasto;
      saldo += p.subProyecto.saldo;
    });
    return { aprobado, gasto, saldo };
  };
  const labels = proyectos.map((p) => p.subProyecto.name);
  const seriesGasto = proyectos.map((p) => p.subProyecto.gasto);
  const seriesSaldo = proyectos.map((p) => p.subProyecto.saldo);
  return (
    <>
      <h2>Resumen inversión presupuesto por proyecto</h2>
      <Row>
        <Col sm="12">
          <AprobadoSubProyecto />
        </Col>
        <Col sm="12">
          <DefaultTable columns={columns}>
            {proyectos.map((proyecto, i) => (
              <tr key={i}>
                <td>{proyecto.name}</td>
                <td>{proyecto.subProyecto.name}</td>
                <td>{formatter.format(proyecto.subProyecto.aprobado)}</td>
                <td>{formatter.format(proyecto.subProyecto.gasto)}</td>
                <td>{formatter.format(proyecto.subProyecto.saldo)}</td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td></td>
              <td style={{ fontWeight: 900 }}>
                {formatter.format(totals().aprobado)}
              </td>
              <td style={{ fontWeight: 900 }}>
                {formatter.format(totals().gasto)}
              </td>
              <td style={{ fontWeight: 900 }}>
                {formatter.format(totals().saldo)}
              </td>
            </tr>
          </DefaultTable>
        </Col>
        <Col>
          <Row>
            <Col xl="6" sm="12">
              <ChartPie
                series={seriesGasto}
                labels={labels}
                title="Gastos"
                subtitle="Gastos por sub proyecto"
              />
            </Col>
            <Col xl="6" sm="12">
              <ChartPie
                series={seriesSaldo}
                labels={labels}
                title="Saldo"
                subtitle="Saldos por sub proyecto"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default ReportesPorProyecto;
