import { useEffect, useState } from "react";
import { Fragment } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import PaginationTable from "../../../components/table/CrudTable";
import CreateModal from "../../../components/user/modal/create";
import ActionModal from "../../../components/user/modal/actions";
import EditModal from "../../../components/user/modal/edit";
import { UserAll } from "../../../hooks/user";
import { tableColumnsUser } from "./data";
import DeleteModal from "../../../components/user/modal/delete";
import { fetchRoles } from "../../../services/roles";
const UserTable = () => {
  const { currentPage, lastPage, users, edit, add, deleted } = UserAll();
  const [modal, setModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [user, setUser] = useState({ id: 0, name: "", email: "" });
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState({ isError: false, message: "" });
  const [actionModal, setActionModal] = useState(false);
  const [userCreate, setUserCreate] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [roles, setRoles] = useState();

  useEffect(() => {
    rolesInit();
  }, []);

  const rolesInit = async () => {
    const response = await fetchRoles();
    setRoles(response.map((item) => ({ value: item.id, label: item.name })));
  };

  const handleEdit = (e) => {
    if (e.target.name === "user") {
      setUser({ ...user, name: e.target.value });
    }
    if (e.target.name === "email") {
      setUser({ ...user, email: e.target.value });
    }
  };
  const actionHandler = (action, user) => {
    if (user !== undefined) {
      setUser({ id: user[0].value, name: user[1].value, email: user[2].value });
    }
    switch (action) {
      case "create":
        setCreateModal(!createModal);
        break;
      case "editar":
        setEditModal(!editModal);
        break;
      case "action":
        setActionModal(!actionModal);
        break;
      case "delete":
        setDeleteModal(!deleteModal);
        break;
      default:
        break;
    }
    setModal(!modal);
  };
  const handleSubmitEdit = async () => {
    const editar = await edit(user);

    setMessage(editar.message);
    setSuccess(editar.success);
    actionHandler("editar");
    actionHandler("action");
  };
  const modalCreate = () => {
    actionHandler("create");
  };
  const handleCreate = (e) => {
    if (e.target !== undefined) {
      if (e.target.name === "user") {
        setUserCreate({ ...userCreate, name: e.target.value });
      }
      if (e.target.name === "email") {
        setUserCreate({ ...userCreate, email: e.target.value });
      }
      if (e.target.name === "password") {
        setUserCreate({ ...userCreate, password: e.target.value });
      }
    } else {
      setUserCreate({ ...userCreate, role_id: e.value });
    }
  };
  const handleSubmitCreate = async () => {
    try {
      const create = await add(userCreate);
      setMessage(create.message);
      setSuccess(create.success);
      actionHandler("create");
      actionHandler("action");
    } catch (errors) {
      setError({ ...error, isError: true, message: errors.errors.email[0] });
    }
  };
  const handleSubmitDelete = async () => {
    const create = await deleted(user);
    setMessage(create.message);
    setSuccess(create.success);
    actionHandler("delete");
    actionHandler("action");
  };
  return (
    <Fragment>
      <Container>
        <Row className="pb-3">
          <Col></Col>
          <Col md="auto"></Col>
          <Col xs lg="2">
            <Button onClick={modalCreate}>Agregar</Button>
          </Col>
        </Row>
      </Container>
      <PaginationTable
        title={"Listado de usuarios"}
        currentPage={currentPage}
        lastPage={lastPage}
        columns={tableColumnsUser}
        data={users}
        toggleModal={actionHandler}
        useHook={UserAll}
      />
      <EditModal
        show={editModal}
        toggleModal={actionHandler}
        submit={handleSubmitEdit}
        user={user}
        title="Editar Usuario"
        handleEdit={handleEdit}
      />
      <CreateModal
        show={createModal}
        toggleModal={actionHandler}
        submit={handleSubmitCreate}
        user={userCreate}
        title="Crear Usuario"
        handleCreate={handleCreate}
        rol={roles}
        error={error}
      />
      <DeleteModal
        show={deleteModal}
        toggleModal={actionHandler}
        submit={handleSubmitDelete}
        type="el usuario"
      />
      <ActionModal
        show={actionModal}
        toggleModal={actionHandler}
        message={message}
        success={success}
      />
    </Fragment>
  );
};

export default UserTable;
