import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";

const INITIALIZE = "INITIALIZE";
const REFRESH = "REFRESH";
const FILTER = "FILTER";

const initialState = {
  currentPage: 1,
  lastPage: 1,
  filter: "",
  users: [],
};

const UserReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        currentPage: 1,
        lastPage: action.payload.lastPage,
        users: action.payload.users,
        filter: action.payload.filter,
      };
    case REFRESH:
      return {
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        users: action.payload.users,
        filter: state.filter,
      };
    case FILTER:
      return {
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        users: action.payload.users,
        filter: action.payload.filter,
      };

    default:
      return state;
  }
};

export const UsersContext = createContext(null);
export function UserProvider({ children }) {
  const [state, dispatch] = useReducer(UserReducer, initialState);
  useEffect(() => {
    const initialize = async () => {
      try {
        const response = await axios.get(`/api/admin/user`);
        const users = response.data.data;
        if (users.length > 0) {
          dispatch({
            type: INITIALIZE,
            payload: {
              users,
              currentPage: 1,
              lastPage: 1,
              filter: "",
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            users: [],
            currentPage: 1,
          },
        });
      }
    };
    initialize();
  }, []);
  const refresh = async (page) => {
    const response = await axios.get(`/api/admin/user`);
    const users = response.data.data;
    dispatch({
      type: REFRESH,
      payload: {
        users,
        currentPage: 1,
        lastPage: 1,
      },
    });
  };
  const edit = async (user) => {
    const response = await axios.patch(`/api/admin/user/${user.id}`, user);
    const { success, message } = response.data;
    refresh();
    return {
      success,
      message,
    };
  };
  const add = async (user) => {
    const response = await axios.post(`/api/admin/user/`, user);
    const { success, message } = response.data;
    refresh();
    return {
      success,
      message,
    };
  };
  const rol = async () => {
    const response = await axios.get(`/api/admin/rol`);
    return response.data.data;
  };
  const deleted = async (user) => {
    const response = await axios.delete(`/api/admin/user/${user.id}`);
    const { success, message } = response.data;
    refresh();
    return {
      success,
      message,
    };
  };
  return (
    <UsersContext.Provider
      value={{
        ...state,
        refresh,
        edit,
        add,
        rol,
        deleted,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}
