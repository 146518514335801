import { Fragment } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import CompanyTable from "./CompanyTable";
const CompanyMaintainer = () => {
  return (
    <Fragment>
      <Helmet title="Mantenedor De Usuarios" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Mantenedor de Empresas</h1>
        <Row>
          <Col>
            <Card>
              <Card.Header></Card.Header>
              <Card.Body>
                <CompanyTable />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CompanyMaintainer;
