import { useQuery } from "react-query";
import { fetchPresupuestoCapacitacion } from "../../../services/presupuestos";

const useReportes = () => {
  //const dimensiones = useQuery(["dimensiones"], fetchDivisiones);
  const stats = useQuery(
    ["inversionPorDivision"],
    fetchPresupuestoCapacitacion
  );
  return stats;
  //const company = useQuery(["company"], fetchCompany);
  //const [isInitialized, setIsInitialized] = useState(false);
  //const [data, setData] = useState(null);
  //const [newData, setNewData] = useState(null);
  //useEffect(() => {
  //  if (stats.data && dimensiones.data && company.data) {
  //    let arr = stats.data.data.map((div) => {
  //      const company_id = dimensiones.data.find(
  //        (d) => d.id === div.division_id
  //      );
  //      const company_name = company.data.data.find(
  //        (c) => c.id === company_id.company_id
  //      );
  //
  //      return {
  //        ...div,
  //        name: dimensiones.data.find((d) => d.id === div.division_id).name,
  //        money: removeSignoPeso(formatter.format(div.sum)),
  //        company: company_name.name,
  //      };
  //    });
  //    setData(arr);
  //    setIsInitialized(true);
  //  }
  //}, [stats.data, dimensiones.data]);
  //
  //const calculateTotal = () => {
  //  let total = 0;
  //  stats.data.data.forEach((div) => {
  //    total += div.sum;
  //  });
  //  return removeSignoPeso(formatter.format(total));
  //};
  //
  //return {
  //  data,
  //  calculateTotal,
  //  isInitialized,
  //};
};
export default useReportes;
