import React from "react";
import { Outlet } from "react-router-dom";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";

import dashboardItems from "../components/sidebar/dashboardItems";
import { ShowChartsProvider } from "../contexts/ShowChartsContext";
import useAuth from "../hooks/useAuth";

const Dashboard = ({ children }) => {
  const { user } = useAuth();
  return (
    <React.Fragment>
      <ShowChartsProvider>
        <Wrapper>
          <Sidebar items={dashboardItems} />
          <Main>
            <Navbar />
            <Content>
              {user && children}
              <Outlet />
            </Content>
            <Footer />
          </Main>
        </Wrapper>
      </ShowChartsProvider>
    </React.Fragment>
  );
};

export default Dashboard;
