import axios from "axios";

const axiosInstance = axios.create({
  // baseURL: "https://apiGestionTalento.test/",
  //baseURL: "https://gestion-talento-api.herokuapp.com",
  baseURL: "https://apidev.gestiondetalento.uduran.xyz",
});

const accessToken = window.localStorage.getItem("accessToken");
if (accessToken) {
  localStorage.setItem("accessToken", accessToken);
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Ha ocurrido un error"
    )
);

export default axiosInstance;
