import React from "react";
import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";
import usePalette from "../../../hooks/usePalette";
import { formatter } from "../../../utils/utils";
import { proyectos } from "../reportes/data";
const AprobadoSubProyecto = () => {
  const palette = usePalette();
  const data = [
    {
      name: "Sub Proyecto",
      data: proyectos.map((p) => p.subProyecto.aprobado),
    },
  ];

  const options = {
    chart: {
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    dataLabels: {
      formatter: function (value) {
        return formatter.format(value);
      },
    },
    xaxis: {
      labels: {
        formatter: function (value) {
          return formatter.format(value);
        },
      },
      categories: [...new Set(proyectos.map((p) => p.subProyecto.name))],
    },
    yaxis: {
      labels: {
        maxWidth: 300,
      },

      title: {
        text: undefined,
      },
    },

    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
    colors: [
      palette.primary,
      palette.success,
      palette.warning,
      palette.danger,
      palette.info,
    ],
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">Sub proyectos</Card.Title>
        <h6 className="card-subtitle text-muted">
          Presupuesto aprobado por sub proyectos
        </h6>
      </Card.Header>
      <Card.Body>
        <div className="chart">
          <Chart options={options} series={data} type="bar" height="500" />
        </div>
      </Card.Body>
    </Card>
  );
};

export default AprobadoSubProyecto;
