import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";

const ChartPie = ({ series, labels, title, subtitle }) => {
  const options = {
    chart: {
      width: 500,
      type: "pie",
    },
    labels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">{title}</Card.Title>
        <h6 className="card-subtitle text-muted">{subtitle}</h6>
      </Card.Header>
      <Card.Body>
        <div className="chart">
          <Chart options={options} series={series} type="pie" height="500" />
        </div>
      </Card.Body>
    </Card>
  );
};
export default ChartPie;
