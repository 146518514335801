import React from "react";
import { Col, Card, Row } from "react-bootstrap";
import { DollarSign, Check, User } from "react-feather";
import useAuth from "../../hooks/useAuth";
import illustration from "./../../assets/img/illustrations/customer-support.png";
import NotDataCard from "../pages/NotDataCard";
import { formatter, removeSignoPeso } from "../../utils/utils";

const Statistics = ({ budget, approvalRate, enrolled }) => {
  const { user } = useAuth();
  return (
    <Row>
      <Col md="6" xl="6" className="d-flex">
        <Card className="illustration flex-fill">
          <Card.Body className="p-0 d-flex flex-fill">
            <Row className="g-0 w-100">
              <Col xs="6">
                <div className="illustration-text p-3 m-1">
                  <h4 className="illustration-text">
                    Bienvenido, {user?.name}
                  </h4>
                  <p className="mb-0">Dashboard Gestión de Talento</p>
                </div>
              </Col>
              <Col xs={6} className="align-self-end text-end">
                <img
                  src={illustration}
                  alt="Customer Support"
                  className="img-fluid illustration-img"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl="6" className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            {budget != null && (
              <div className="d-flex align-items-start">
                <div className="flex-grow-1">
                  <h3 className="mb-2">
                    {budget &&
                      budget.toLocaleString("es-CL", {
                        style: "currency",
                        currency: "CLP",
                      })}
                  </h3>

                  <p className="mb-2">Presupuesto Utilizados</p>
                  {/*<div className="mb-0">
                    <Badge bg="" className="badge-soft-success me-2">
                      +5.35%
                    </Badge>
                    <span className="text-muted">Más que el año 2020</span>
                  </div>*/}
                </div>
                <div className="d-inline-block ms-3">
                  <div className="stat">
                    <DollarSign className="align-middle text-success" />
                  </div>
                </div>
              </div>
            )}
            {budget === null && <NotDataCard />}
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl="6" className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            {approvalRate !== null ? (
              <div className="d-flex align-items-start">
                <div className="flex-grow-1">
                  <h3 className="mb-2">{approvalRate}%</h3>
                  <p className="mb-2">Porcentaje de aprobación</p>
                </div>
                <div className="d-inline-block ms-3">
                  <div className="stat">
                    <Check className="align-middle text-success" />
                  </div>
                </div>
              </div>
            ) : (
              <NotDataCard />
            )}
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl="6" className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            {enrolled > 0 ? (
              <div className="d-flex align-items-start">
                <div className="flex-grow-1">
                  <h3 className="mb-2">
                    {removeSignoPeso(formatter.format(enrolled))}
                  </h3>
                  <p className="mb-2">
                    Trabajadores inscritos en al menos un curso
                  </p>
                </div>
                <div className="d-inline-block ms-3">
                  <div className="stat">
                    <User className="align-middle text-success" />
                  </div>
                </div>
              </div>
            ) : (
              <NotDataCard />
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Statistics;
