import React from "react";
import { Col, Row } from "react-bootstrap";

const SexCompare = ({ stats, info }) => {
  return (
    <Row>
      {stats &&
        stats.map((item, index) => (
          <Col md="6" key={index}>
            <div className="text-center">
              {item.gender === 1 && (
                <img
                  alt="Chris Wood"
                  src={info[1].avatar}
                  className="rounded-circle img-responsive mt-2"
                  width="128"
                  height="128"
                />
              )}
              {item.gender === 1 && (
                <div className="mt-2">
                  <div className="mb-4">
                    <span className="display-4">
                      {item.data.toLocaleString("es-CL")}
                    </span>
                    <br></br>
                    <span>{info[1].name}</span>
                  </div>
                </div>
              )}
              {item.gender === 2 && (
                <img
                  alt="Chris Wood"
                  src={info[0].avatar}
                  className="rounded-circle img-responsive mt-2"
                  width="128"
                  height="128"
                />
              )}
              {item.gender === 2 && (
                <div className="mt-2">
                  <div className="mb-4">
                    <span className="display-4">
                      {item.data.toLocaleString("es-CL")}
                    </span>
                    <br></br>
                    <span>{info[0].name}</span>
                  </div>
                </div>
              )}
            </div>
          </Col>
        ))}
    </Row>
  );
};

export default SexCompare;
