import React from "react";
import { Button, Modal } from "react-bootstrap";

const DeleteModal = ({ show, toggleModal, submit, type }) => {
  return (
    <React.Fragment>
      <Modal show={show} onHide={() => toggleModal("delete")} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center m-3">
          <p className="mb-0">¿Esta seguro que desea eliminar {type}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger" onClick={submit}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteModal;
