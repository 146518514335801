import React from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import aviso from "../../assets/img/aviso.png";
const NotData = ({ title }) => (
  <React.Fragment>
    <Container fluid className="p-0">
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                <h1 className="h3 mb-3">{title}</h1>
              </Card.Title>
            </Card.Header>
            <Card.Body className="text-center">
              <Image src={aviso} width="100" />
              <p style={{ fontSize: "20px" }}>
                Debes Ingresar datos para poder ver la informacion
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default NotData;
