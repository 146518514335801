import React, { Fragment } from "react";
import { useQuery } from "react-query";
import Chart from "react-apexcharts";
import { Card, Spinner } from "react-bootstrap";
import usePalette from "../../../../hooks/usePalette";
import { fetchInscritosAlmenosUnCurso } from "../../../../services/capacitaciones";
import NotData from "../../../pages/NotData";
const InscritosAlmenosUnCurso = () => {
  const palette = usePalette();
  const {
    data: stats,
    error,
    isLoading,
  } = useQuery(["inscritosAlmenosUnCurso"], fetchInscritosAlmenosUnCurso);
  if (isLoading) {
    return <Spinner animation="border" />;
  }
  if (error) {
    return <p>Hubo un error inesperado</p>;
  }
  if (stats.length === 0 || !stats) {
    return (
      <NotData title="No existen datos en el grafico de Trabajadores inscritos en al menos un curso" />
    );
  }
  const data = [
    {
      name: "Hombres",
      data: stats
        .filter((item) => item.gender === 1)
        .map((item) => item.participations),
    },
    {
      name: "Mujeres",
      data: stats
        .filter((item) => item.gender === 2)
        .map((item) => item.participations),
    },
  ];

  const options = {
    chart: {
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [5, 7, 5],
      curve: "smooth",
      dashArray: [0, 8, 5],
    },
    markers: {
      size: 0,
      style: "hollow", // full, hollow, inverted
    },
    xaxis: {
      categories: [],
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val) {
              return val;
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val;
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val;
            },
          },
        },
      ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    colors: [
      palette.primary,
      palette.success,
      palette.warning,
      palette.danger,
      palette.info,
    ],
  };

  return (
    <Card>
      {stats.length > 0 && (
        <Fragment>
          <Card.Header>
            <Card.Title tag="h5">Resultados</Card.Title>
            <h6 className="card-subtitle text-muted">
              Trabajadores inscritos en al menos un curso.
            </h6>
          </Card.Header>
          <Card.Body>
            <div className="chart">
              <Chart options={options} series={data} type="line" height="350" />
            </div>
          </Card.Body>
        </Fragment>
      )}
    </Card>
  );
};

export default InscritosAlmenosUnCurso;
