import React from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarNav from "./SidebarNav";
import useAuth from "../../hooks/useAuth";
import { ROL } from "../../utils/utils";

const Sidebar = ({ items }) => {
  const { isOpen } = useSidebar();
  const { user } = useAuth();
  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            <div className="text-center">
              <span className="align-middle me-3">Gestión de Talento</span>
              {user !== null && (
                <img
                  src={user.preference.preference.logo}
                  alt="Banner"
                  className="img-fluid"
                  width="400"
                />
              )}
            </div>
          </a>
          {user && user.role_id === ROL.Administracion && (
            <SidebarNav items={items.admin} />
          )}
          {user && user.role_id === ROL.SuperAdministrador && (
            <SidebarNav items={items.admin} />
          )}
          {user && user.role_id === ROL.Visualizacion && (
            <SidebarNav items={items.cliente} />
          )}
          {user && user.role_id === ROL.Gestion && (
            <SidebarNav items={items.cliente} />
          )}
          {/*user && user.role_id === ROL.SuperAdministrador && (
            <SidebarNav items={items.cliente} />
          )*/}
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
