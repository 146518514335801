import * as React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children, canAccess = [] }) {
  const { isAuthenticated, isInitialized, user } = useAuth();
  if (
    (isInitialized && !isAuthenticated) ||
    (user && !canAccess.includes(user.role_id))
  ) {
    return <Navigate to="/auth/sign-in" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
