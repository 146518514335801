import { useContext } from "react";
import { CompaniesContext } from "../contexts/CompanyContext";

export const ComapnyAll = () => {
  const context = useContext(CompaniesContext);
  if (!context)
    throw new Error(
      "El usuarioContext debe colocarse colocarse dentro del provider"
    );
  return context;
};
