import ReactApexChart from "react-apexcharts";
import { Card } from "react-bootstrap";
import NotDataCard from "../../pages/pages/NotDataCard";

export const ChartBarPorcentaje = (data) => {
  const state = {
    series: [
      {
        data: data.data.map((d) => d.round),
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "45%",
          borderRadius: 10,
          distributed: true,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "%";
        },
        offsetY: 15,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: data.data.map((d) => d.name),
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
    },
  };
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">
          Porcentaje del estado de las capacitaciones
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {data.data.length > 0 ? (
          <ReactApexChart
            options={state.options}
            series={state.series}
            type="bar"
            height={350}
          />
        ) : (
          <NotDataCard />
        )}
      </Card.Body>
    </Card>
  );
};
