import { matchSorter } from "match-sorter";
import React from "react";
import { Form } from "react-bootstrap";
import { useAsyncDebounce } from "react-table";

function GlobalFilter({ globalFilter, setGlobalFilter, useHook }) {
  const { filter } = useHook();
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || "");
    filter(value || "");
  }, 200);

  return (
    <span>
      <Form.Control
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Buscar`}
      />
    </span>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = (val) => !val;

export { GlobalFilter, fuzzyTextFilterFn };
