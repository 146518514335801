import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import ReactSelect from "react-select";
import useSidebar from "../../hooks/useSidebar";
import { UseUtilsCompanySelect } from "../../hooks/useUtils";
import { fetchAdminCompany } from "../../services/admin";
import NavbarUser from "./NavbarUser";

const NavbarComponent = () => {
  const { isOpen, setIsOpen } = useSidebar();
  const [availableCompanies, setAvailableCompanies] = useState(false);
  const { mutate: companySelect } = UseUtilsCompanySelect();
  const handleSelect = (e) => {
    companySelect({ selects: e });
  };

  const initAvailableCompanies = async () => {
    const res = await fetchAdminCompany();
    setAvailableCompanies(res);
  };

  useEffect(() => {
    initAvailableCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Navbar variant="light" expand className="navbar-bg">
      <span
        className="sidebar-toggle d-flex"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <i className="hamburger align-self-center" />
      </span>

      {availableCompanies.length > 0 && (
        <ReactSelect
          closeMenuOnSelect={false}
          isMulti
          options={availableCompanies}
          className="react-select-container w-50"
          onChange={handleSelect}
          onBlur={() => window.location.reload()}
          defaultValue={availableCompanies.filter((c) => c.selected)}
        />
      )}

      <Navbar.Collapse>
        <Nav className="navbar-align">
          <NavbarUser />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
