import axios from "../utils/axios";

export const fetchPresupuestoCapacitacion = async () => {
  try {
    const { data } = await axios.get("/api/budget/stats?by=division");
    return data;
  } catch (error) {
    console.log("Error fetchPresupuestoCapacitacion", error);
    return null;
  }
};
export const fetchDivisiones = async () => {
  try {
    const { data } = await axios.get("/api/division");
    return data;
  } catch (error) {
    console.log("Error fetchDivisiones", error);
    return null;
  }
};

export const fetchCompany = async () => {
  try {
    const { data } = await axios.get("/api/admin/company");
    return data;
  } catch (error) {
    console.log("Error fetchDivisiones", error);
    return null;
  }
};
