import { React } from "react";

import { Dropdown } from "react-bootstrap";

import { User } from "react-feather";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const NavbarUser = () => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <span className="text-dark">{user && user.name} </span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        <Dropdown.Item onClick={() => navigate("/perfil")}>
          <User size={18} className="align-middle me-2" />
          Perfil
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={signOut}>Cerrar Sesión</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
