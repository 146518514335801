import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";

const INITIALIZE = "INITIALIZE";
const REFRESH = "REFRESH";
const FILTER = "FILTER";

const initialState = {
  currentPage: 1,
  lastPage: 1,
  filter: "",
  companies: [],
};

const CompanyReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        currentPage: 1,
        lastPage: action.payload.lastPage,
        companies: action.payload.companies,
        filter: action.payload.filter,
      };
    case REFRESH:
      return {
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        companies: action.payload.companies,
        filter: state.filter,
      };
    case FILTER:
      return {
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        companies: action.payload.companies,
        filter: action.payload.filter,
      };

    default:
      return state;
  }
};

export const CompaniesContext = createContext(null);
export function CompanyProvider({ children }) {
  const [state, dispatch] = useReducer(CompanyReducer, initialState);
  useEffect(() => {
    const initialize = async () => {
      try {
        const response = await axios.get(`/api/admin/company`);
        const companies = response.data.data;
        if (companies.length > 0) {
          dispatch({
            type: INITIALIZE,
            payload: {
              companies,
              currentPage: 1,
              lastPage: 1,
              filter: "",
            },
          });
        }
      } catch (err) {
        dispatch({
          type: INITIALIZE,
          payload: {
            users: [],
            currentPage: 1,
          },
        });
      }
    };
    initialize();
  }, []);
  const refresh = async (page) => {
    const response = await axios.get(`/api/admin/company`);
    const companies = response.data.data;
    dispatch({
      type: REFRESH,
      payload: {
        companies,
        currentPage: 1,
        lastPage: 1,
      },
    });
  };
  const edit = async (company) => {
    const response = await axios.patch(
      `/api/admin/company/${company.id}`,
      company
    );
    const { success, message } = response.data;
    refresh();
    return {
      success,
      message,
    };
  };
  const add = async (company) => {
    const response = await axios.post(`/api/admin/company/`, company);
    const { success, message } = response.data;
    refresh();
    return {
      success,
      message,
    };
  };
  const deleted = async (company) => {
    const response = await axios.delete(`/api/admin/company/${company.id}`);
    const { success, message } = response.data;
    refresh();
    return {
      success,
      message,
    };
  };
  return (
    <CompaniesContext.Provider
      value={{
        ...state,
        refresh,
        edit,
        add,
        deleted,
      }}
    >
      {children}
    </CompaniesContext.Provider>
  );
}
