import React from "react";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

import upload from "../../assets/icons/upload.png";

const Navigation = () => (
  <Card>
    <Card.Header>
      <Card.Title tag="h5" className="mb-0">
        Gestión
      </Card.Title>
    </Card.Header>
    <ListGroup variant="flush">
      <ListGroup.Item tag="a" href="#" action active>
        Carga de datos
      </ListGroup.Item>
      <ListGroup.Item tag="a" href="#" action>
        Cursos
      </ListGroup.Item>
      <ListGroup.Item tag="a" href="#" action>
        Proveedores
      </ListGroup.Item>
      <ListGroup.Item tag="a" href="#" action>
        Diccionario
      </ListGroup.Item>
      <ListGroup.Item tag="a" href="#" action>
        Familia O Programa
      </ListGroup.Item>
      <ListGroup.Item tag="a" href="#" action>
        Colaboradores
      </ListGroup.Item>
    </ListGroup>
  </Card>
);

const TrainingsUpload = () => (
  <Card>
    <Card.Header>
      <Card.Title tag="h5" className="mb-0">
        Carga de datos
      </Card.Title>
    </Card.Header>
    <Card.Body>
      <Form>
        <Row>
          <Col md="4">
            <div className="text-center">
              <img
                alt="Chris Wood"
                src={upload}
                className="rounded-circle img-responsive mt-2"
                width="128"
                height="128"
              />
              <div className="mt-2">
                <Button variant="primary">
                  <FontAwesomeIcon icon={faUpload} /> Cargar
                </Button>
              </div>
            </div>
          </Col>
          <Col md="8">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="inputUsername">
                Título de los datos
              </Form.Label>
              <Form.Control type="text" id="inputUsername" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="inputBio">
                Descripción para la carga de datos
              </Form.Label>
              <Form.Control as="textarea" rows="2" id="inputBio" />
            </Form.Group>
          </Col>
        </Row>

        <Button className="mt-5" variant="primary">
          Cargar registros de capacitaciones
        </Button>
      </Form>
    </Card.Body>
  </Card>
);

const Settings = () => (
  <React.Fragment>
    <Helmet title="Settings" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Administración de capacitaciones</h1>

      <Row>
        <Col md="3" xl="2">
          <Navigation />
        </Col>
        <Col md="9" xl="10">
          <TrainingsUpload />
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default Settings;
