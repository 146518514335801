import axios from "../utils/axios";

export const fetchAverageHoursByDivision = async () => {
  try {
    const { data } = await axios.get(
      "api/participation/report?filterby=genderHourAverageByDivision"
    );
    return data.data;
  } catch (error) {
    console.log("Error fetchAverageHoursByDivision", error);
    return null;
  }
};
export const fetchAverageHoursMaleFemale = async () => {
  try {
    const { data } = await axios.get(
      "api/participation/report?filterby=genderHourAverage"
    );
    return data.data;
  } catch (error) {
    console.log("Error fetchAverageHoursMaleFemalte", error);
    return null;
  }
};
export const fetchCapacitadosPorDimension = async () => {
  try {
    const { data } = await axios.get(
      "api/participation/report?filterby=division"
    );
    return data.data;
  } catch (error) {
    console.log("Error fetchCapacitadosPorDimension", error);
    return null;
  }
};
export const fetchHoursMaleFemale = async () => {
  try {
    const { data } = await axios.get(
      "api/participation/report?filterby=genderHoursSum"
    );
    return data.data;
  } catch (error) {
    console.log("Error fetchHoursMaleFemale", error);
    return null;
  }
};
export const fetchInscritosAlmenosUnCurso = async () => {
  try {
    const { data } = await axios.get(
      "api/participation/report?filterby=gender"
    );
    return data.data;
  } catch (error) {
    console.log("Error fetchInscritosAlmenosUnCurso", error);
    return null;
  }
};
export const fetchMaleFemale = async () => {
  try {
    const { data } = await axios.get(
      "api/participation/report?filterby=genderSum"
    );
    return data.data;
  } catch (error) {
    console.log("Error fetchMaleFemale", error);
    return null;
  }
};
