import React from "react";

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
// import LandingLayout from "./layouts/Landing";

// Guards
// import AuthGuard from "./components/guards/AuthGuard";

// Auth
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";

// Protected routes
import { ParticipationProvider } from "./contexts/ParticipationContext";

//Dashboard
import Inicio from "./pages/dashboard";
//Capacitaciones
import UniversoCapacitaciones from "./pages/training/universo";
import ReportesCapacitaciones from "./pages/training/reportes";
import Settings from "./pages/training/settings";
//Presupuestos
import ReportesPresupuesto from "./pages/budget/reportes";
import ReportesPorProyecto from "./pages/budget/reportes/projects";
import AuthGuard from "./components/guards/AuthGuard";
import { ROL } from "./utils/utils";
import CargarInformacionPage from "./pages/admin/cargar-informacion";
//Dimensiones
import Perfil from "./pages/mi-perfil/Pefil";
import UserMaintainer from "./pages/admin/users";
import { UserProvider } from "./contexts/UsersContext";
import { CompanyProvider } from "./contexts/CompanyContext";
import CompanyMaintainer from "./pages/admin/company";
import ReporteResultado from "./pages/result";
const routes = [
  {
    path: "/",
    element: (
      <AuthGuard
        canAccess={[
          ROL.Administracion,
          ROL.Gestion,
          ROL.SuperAdministrador,
          ROL.Visualizacion,
        ]}
      >
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Inicio />,
      },
    ],
  },
  {
    path: "training",
    element: (
      <ParticipationProvider>
        <AuthGuard
          canAccess={[
            ROL.Administracion,
            ROL.Gestion,
            ROL.SuperAdministrador,
            ROL.Visualizacion,
          ]}
        >
          <DashboardLayout />
        </AuthGuard>
      </ParticipationProvider>
    ),
    children: [
      {
        path: "list",
        element: <UniversoCapacitaciones />,
      },
      {
        path: "reports",
        element: <ReportesCapacitaciones />,
      },
      {
        path: "management",
        element: <Settings />,
      },
    ],
  },
  {
    path: "budget",
    element: (
      <ParticipationProvider>
        <AuthGuard
          canAccess={[
            ROL.Administracion,
            ROL.Gestion,
            ROL.SuperAdministrador,
            ROL.Visualizacion,
          ]}
        >
          <DashboardLayout />
        </AuthGuard>
      </ParticipationProvider>
    ),
    children: [
      {
        path: "summary",
        element: <UniversoCapacitaciones />,
      },
      {
        path: "reports-dimension",
        element: <ReportesPresupuesto />,
      },
      {
        path: "reports-projects",
        element: <ReportesPorProyecto />,
      },
      {
        path: "management",
        element: <Settings />,
      },
    ],
  },
  {
    path: "perfil",
    element: (
      <ParticipationProvider>
        <AuthGuard
          canAccess={[
            ROL.Administracion,
            ROL.Gestion,
            ROL.SuperAdministrador,
            ROL.Visualizacion,
          ]}
        >
          <DashboardLayout />
        </AuthGuard>
      </ParticipationProvider>
    ),
    children: [
      {
        path: "",
        element: <Perfil />,
      },
    ],
  },
  {
    path: "areas",
    element: (
      <ParticipationProvider>
        <AuthGuard
          canAccess={[
            ROL.Administracion,
            ROL.Gestion,
            ROL.SuperAdministrador,
            ROL.Visualizacion,
          ]}
        >
          <DashboardLayout />
        </AuthGuard>
      </ParticipationProvider>
    ),
    children: [
      {
        path: "",
        element: <UniversoCapacitaciones />,
      },
      {
        path: "reports",
        element: <ReportesCapacitaciones />,
      },
      {
        path: "management",
        element: <Settings />,
      },
    ],
  },
  {
    path: "results",
    element: (
      <ParticipationProvider>
        <AuthGuard
          canAccess={[
            ROL.Administracion,
            ROL.Gestion,
            ROL.SuperAdministrador,
            ROL.Visualizacion,
          ]}
        >
          <DashboardLayout />
        </AuthGuard>
      </ParticipationProvider>
    ),
    children: [
      {
        path: "",
        element: <UniversoCapacitaciones />,
      },
      {
        path: "reports",
        element: <ReporteResultado />,
      },
      {
        path: "management",
        element: <Settings />,
      },
    ],
  },
  {
    path: "evaluations",
    element: (
      <ParticipationProvider>
        <AuthGuard
          canAccess={[
            ROL.Administracion,
            ROL.Gestion,
            ROL.SuperAdministrador,
            ROL.Visualizacion,
          ]}
        >
          <DashboardLayout />
        </AuthGuard>
      </ParticipationProvider>
    ),
    children: [
      {
        path: "",
        element: <UniversoCapacitaciones />,
      },
      {
        path: "reports",
        element: <ReportesCapacitaciones />,
      },
      {
        path: "management",
        element: <Settings />,
      },
    ],
  },
  {
    path: "admin",
    element: (
      <CompanyProvider>
        <UserProvider>
          <AuthGuard
            canAccess={[
              ROL.Administracion,
              ROL.Gestion,
              ROL.SuperAdministrador,
            ]}
          >
            <DashboardLayout />
          </AuthGuard>
        </UserProvider>
      </CompanyProvider>
    ),
    children: [
      {
        path: "users",
        element: <UserMaintainer />,
      },
      {
        path: "upload",
        element: <CargarInformacionPage />,
      },
      {
        path: "company",
        element: <CompanyMaintainer />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "test",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
