const tableColumnsCompany = [
  {
    Header: "id",
    accessor: "id",
  },
  {
    Header: "Nombre",
    accessor: "name",
  },
  {
    Header: "Rut",
    accessor: "rut",
  },
];

export { tableColumnsCompany };
