import ReactApexChart from "react-apexcharts";
import { Card } from "react-bootstrap";
import NotDataCard from "../../pages/pages/NotDataCard";
import { formatter, removeSignoPeso } from "../../utils/utils";

export const TipoCurso = (data) => {
  const state = {
    series: [
      {
        data: data.data.map((d) => d.count),
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "45%",
          borderRadius: 10,
          distributed: true,
        },
      },
      dataLabels: {
        formatter: function (value) {
          return removeSignoPeso(formatter.format(value));
        },
        offsetY: 15,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: data.data.map((d) => d.name),
        labels: {
          formatter: function (value) {
            return removeSignoPeso(formatter.format(value));
          },
        },
      },
    },
  };
  return (
    <Card style={{ width: "100%", marginTop: "2em" }}>
      <Card.Header>
        <h6 className="card-subtitle text-muted">
          Modalidad de los cursos (cantidad de cursos por modalidad).
        </h6>
      </Card.Header>
      <Card.Body>
        {data.data.length > 0 ? (
          <ReactApexChart
            options={state.options}
            series={state.series}
            type="bar"
            height={350}
          />
        ) : (
          <NotDataCard />
        )}
      </Card.Body>
    </Card>
  );
};
