export const ROL = {
  SuperAdministrador: 1,
  Administracion: 2,
  Gestion: 3,
  Visualizacion: 4,
};
export const formatter = new Intl.NumberFormat("es-CL", {
  style: "currency",
  currency: "CLP",
  minimumFractionDigits: 0,
});
export const removeSignoPeso = (val) => {
  return val.replace("$", "");
};
