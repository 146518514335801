import React, { Fragment } from "react";
import { useQuery } from "react-query";
import { Card, Spinner } from "react-bootstrap";
import avatar1 from "../../../../assets/img/avatars/female.png";
import avatar2 from "../../../../assets/img/avatars/male.png";
import SexCompare from "../../../../components/charts/sexcompare";
import { fetchMaleFemale } from "../../../../services/capacitaciones";
import NotData from "../../../pages/NotData";

const info = [
  {
    avatar: avatar1,
    name: "Mujeres (rut único)",
  },
  {
    avatar: avatar2,
    name: "Hombres (rut único)",
  },
];

const MaleFemale = () => {
  const {
    data: stats,
    error,
    isLoading,
  } = useQuery(["maleFemale"], fetchMaleFemale);
  if (isLoading) {
    return <Spinner animation="border" />;
  }
  if (error) {
    return <p>Hubo un error inesperado</p>;
  }
  if (stats.length === 0 || !stats) {
    return (
      <NotData title="No existen datos en el grafico de Trabajadores inscritos" />
    );
  }
  return (
    <Card>
      {stats.length > 0 && (
        <Fragment>
          <Card.Header>
            <Card.Title tag="h5" className="mb-0">
              Trabajadores inscritos (
              {stats.reduce((acc, curr) => curr.data + acc, 0)})
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <SexCompare stats={stats} info={info} />
          </Card.Body>
        </Fragment>
      )}
    </Card>
  );
};

export default MaleFemale;
