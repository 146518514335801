import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";

const INITIALIZE = "INITIALIZE";
const REFRESH = "REFRESH";
const UPDATE = "UPDATE";
const FILTER = "FILTER";

const initialState = {
  currentPage: 1,
  lastPage: 1,
  filter: "",
  participations: [],
};

const ParticipationReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        currentPage: 1,
        lastPage: action.payload.lastPage,
        participations: action.payload.participations,
        filter: action.payload.filter,
      };
    case REFRESH:
      return {
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        participations: action.payload.participations,
        filter: state.filter,
      };
    case FILTER:
      return {
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        participations: action.payload.participations,
        filter: action.payload.filter,
      };

    default:
      return state;
  }
};

export const ParticipationContext = createContext(null);

export function ParticipationProvider({ children }) {
  const [state, dispatch] = useReducer(ParticipationReducer, initialState);
  useEffect(() => {
    const initialize = async () => {
      try {
        const response = await axios.get(`/api/participation?page=${1}`);
        const {
          current_page: currentPage,
          data: participations,
          last_page: lastPage,
        } = response.data.data;
        if (participations.length > 0) {
          dispatch({
            type: INITIALIZE,
            payload: {
              participations,
              currentPage,
              lastPage,
              filter: "",
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            participations: [],
            currentPage: 1,
          },
        });
      }
    };

    initialize();
  }, []);

  const create = async (email, password) => {
    const response = await axios.post("/api/auth/sign-in", {
      email,
      password,
    });
    const { user } = response.data;

    dispatch({
      type: UPDATE,
      payload: {
        user,
      },
    });
  };

  const refresh = async (page) => {
    const response = await axios.get(
      `/api/participation?page=${page}&filter=${state.filter}`
    );
    const {
      current_page: currentPage,
      data: participations,
      last_page: lastPage,
    } = response.data.data;

    dispatch({
      type: REFRESH,
      payload: {
        participations,
        currentPage,
        lastPage,
      },
    });
  };

  const filter = async (filter) => {
    const response = await axios.get(
      `/api/participation?page=${state.currentPage}&filter=${filter}`
    );
    const {
      current_page: currentPage,
      data: participations,
      last_page: lastPage,
    } = response.data.data;

    dispatch({
      type: FILTER,
      payload: {
        participations,
        currentPage,
        lastPage,
        filter,
      },
    });
  };

  const remove = (email) => console.log(email);

  return (
    <ParticipationContext.Provider
      value={{
        ...state,
        create,
        filter,
        refresh,
        remove,
      }}
    >
      {children}
    </ParticipationContext.Provider>
  );
}
