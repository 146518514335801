import { useContext } from "react";

import { ParticipationContext } from "../contexts/ParticipationContext";

const useParticipations = () => {
  const context = useContext(ParticipationContext);

  if (!context)
    throw new Error(
      "El ParticipationContext debe colocarse colocarse dentro del provider"
    );

  return context;
};

export default useParticipations;
