import { Alert } from "react-bootstrap";
import RenderIf from "./RenderIf";

const Alerts = ({ successIf, failedIf, succesText, failedText }) => {
  return (
    <>
      <RenderIf isTrue={successIf}>
        <Alert variant="success" style={{ padding: "0.5em" }}>
          {succesText}
        </Alert>
      </RenderIf>
      <RenderIf isTrue={failedIf}>
        <Alert variant="danger" style={{ padding: "0.5em" }}>
          {failedText}
        </Alert>
      </RenderIf>
    </>
  );
};
export default Alerts;
