import React, { Fragment } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import avatar1 from "../../../../assets/img/avatars/female.png";
import avatar2 from "../../../../assets/img/avatars/male.png";
import SexCompare from "../../../../components/charts/sexcompare";
import { fetchAverageHoursMaleFemale } from "../../../../services/capacitaciones";
import NotData from "../../../pages/NotData";

const info = [
  {
    avatar: avatar1,
    name: "Horas promedio Mujeres",
  },
  {
    avatar: avatar2,
    name: "Horas promedio Hombres",
  },
];

const AverageHoursMaleFemale = () => {
  const {
    data: stats,
    error,
    isLoading,
  } = useQuery(["averageHoursMaleFemale"], fetchAverageHoursMaleFemale);
  if (!stats) {
    return (
      <NotData title="No existen datos en el grafico de Horas en promedio por trabajador" />
    );
  }
  if (isLoading) {
    return <Spinner animation="border" />;
  }
  if (error) {
    return <p>Hubo un error inesperado</p>;
  }

  return (
    <Card>
      {stats.length > 0 && (
        <Fragment>
          <Card.Header>
            <Card.Title tag="h5" className="mb-0">
              Horas en promedio por trabajador (
              {(
                stats.reduce((acc, curr) => Number(curr.data) + acc, 0) /
                stats.length
              ).toFixed(2)}
              )
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <SexCompare stats={stats} info={info} />
          </Card.Body>
        </Fragment>
      )}
    </Card>
  );
};

export default AverageHoursMaleFemale;
