import {
  CheckSquare,
  DollarSign,
  Eye,
  List,
  Percent,
  Sliders,
  User,
  Upload,
  Briefcase,
} from "react-feather";

const trainingSection = [
  {
    href: "/",
    icon: Sliders,
    title: "Dashboard",
  },
  {
    href: "/training",
    icon: CheckSquare,
    title: "Capacitaciones",
    children: [
      {
        href: "/training/list",
        icon: CheckSquare,
        title: "Universo",
      },
      {
        href: "/training/reports",
        icon: List,
        title: "Reportes",
      },
    ],
  },
  {
    href: "/budget",
    icon: DollarSign,
    title: "Presupuestos",
    children: [
      {
        href: "",
        icon: List,
        title: "Reportes",
        children: [
          {
            href: "/budget/reports-dimension",
            title: "Por Dimensión",
          },
          //{
          //  href: "/budget/reports-projects",
          //  title: "Por proyecto",
          //},
        ],
      },
    ],
  },
  {
    href: "/areas",
    icon: Eye,
    title: "Dimensiones",
    children: [
      {
        href: "/areas/reports",
        icon: List,
        title: "Reportes",
      },
    ],
  },
  {
    href: "/results",
    icon: Percent,
    title: "Resultados",
    children: [
      {
        href: "/results/reports",
        icon: List,
        title: "Reportes",
      },
    ],
  },
];
const adminSection = [
  {
    href: "/admin/upload",
    icon: Upload,
    title: "Cargar información",
  },
  {
    href: "/admin/users",
    icon: User,
    title: "Usuarios",
  },
  {
    href: "/admin/company",
    icon: Briefcase,
    title: "Empresa",
  },
];
const navItems = {
  cliente: [
    {
      title: "Principal",
      pages: trainingSection,
    },
  ],
  admin: [
    {
      title: "Principal",
      pages: trainingSection,
    },
    {
      title: "Administración",
      pages: adminSection,
    },
  ],
};
// {
//   title: "Pages",
//   pages: pagesSection,
// },
// {
//   title: "Tools & Components",
//   pages: componentsSection,
// },
// {
//   title: "Plugins & Addons",
//   pages: pluginsSection,
// },

export default navItems;
