import { useMutation } from "react-query";
import { UsersContext } from "../contexts/UsersContext";
import { fetchUserUpdate } from "../services/user";
import { useContext } from "react";

export const useUserMutate = () => {
  return useMutation(fetchUserUpdate);
};
export const UserAll = () => {
  const context = useContext(UsersContext);
  if (!context)
    throw new Error(
      "El usuarioContext debe colocarse colocarse dentro del provider"
    );
  return context;
};
